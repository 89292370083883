import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { SoonLabel } from 'components/contexts/home/PlenaryCard/PlenaryCard.styles';

type WrapperProps = {
  hide?: boolean;
};

type InteractionProps = {
  position?: string;
};

const wrapperModifiers = {
  hide: () => css`
    .react-jwp-player #player-tm1 {
      /* margin: 0 auto; */
    }
    .react-jwp-player {
      opacity: 1;
      visibility: visible;
    }
    //teste
    .jw-horizontal-volume-container,
    .jw-slider-time:not(.jw-chapter-slider-time) {
      z-index: 0 !important;
      outline: 0;
    }
  `,
};

export const PlayerVideo = styled.div`
  position: relative;

  ${media.greaterThan('medium')`
    height: 93%;
    max-width: calc( (100vw / 2) + (100vh / 2) - 140px);
    margin: auto;
    .react-jwp-player {
      height: 100%;
      display: flex;
    }
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, hide }) => css`
    #player-tm1 .jw-icon-pip {
      display: none;
    }
    position: relative;
    .react-jwp-player {
      opacity: 0;
      visibility: hidden;
    }

    @media (min-width: 1170px) {
      height: 78vh;

      .react-jwp-player {
        height: 100%;
        display: flex;
      }

      .react-jwp-player #player-tm1 {
        max-width: calc((100vw / 2) + (100vh / 2) - 140px);
        margin: auto;
      }
      .jw-wrapper {
        background-color: transparent;
      }
    }

    @media screen and (max-width: 1400px) and (min-width: 1170px) {
      height: 67vh;
    }

    ${media.greaterThan('large')`
      padding: 0 ${theme.spacing['2xl']};
    `}

    ${!hide && wrapperModifiers.hide()}
  `}
`;

export const UnmuteLabel = styled(SoonLabel)`
  ${() => css`
    z-index: ${10};
  `}
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const Interaction = styled.div<InteractionProps>`
  background: transparent;
  pointer-events: none;
  display: flex;
  padding: 0 40px;
  justify-content: ${({ position }) =>
    position === 'left' ? 'start' : position === 'right' ? 'end' : 'center'};
  align-items: center;
  position: relative;
  bottom: 10%;
  @media (max-width: 414px) {
    bottom: 0px;
  }
  @media (max-width: 1170px) {
    bottom: 6px;
  }
  @media (min-width: 1900px) {
    bottom: 6%;
  }

  @media (min-width: 1171px) {
    bottom: calc(8vw);
  }
  @media (min-width: 1240px) {
    bottom: calc(7vw);
  }

  @media (min-width: 1257px) {
    bottom: calc(2vw);
  }

  @media (min-width: 1373px) {
    bottom: calc(4vw);
  }
  @media (min-width: 1425px) {
    bottom: calc(2vw);
  }
`;
