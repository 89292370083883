import { Logo } from 'components/structure';
import * as S from './ErrorMessage.styles';

type MessageErrorProps = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
};

export const ErrorMessage = ({
  title,
  subtitle,
  children,
}: MessageErrorProps) => (
  <S.Container>
    <S.Logo>
      <Logo />
    </S.Logo>
    <S.Title>{title}</S.Title>
    <S.Subtitle>{subtitle}</S.Subtitle>
    {children}
  </S.Container>
);
