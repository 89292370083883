import { isIOS, isMobile } from 'react-device-detect';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { THEME } from 'styles/themes';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.background};
  overflow: hidden;
  width: 100%;
  height: ${isIOS && isMobile ? '100%' : '100vh'};
`;

export const OutletWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.background};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.lg} ${theme.spacing['2xl']} ${theme.spacing['3xl']};
    height: 100%;
    width: 50%;
    gap: 40px;

    ${media.lessThan('large')`
      padding: ${theme.spacing.xl};
    `}

    ${media.lessThan('medium')`
      padding: ${theme.spacing.lg};
      width: 80%;
    `}

    ${media.lessThan('small')`
      padding: ${theme.spacing.md};
      width: 100%;
    `}
  `}
`;

export const HeroWrapper = styled.div`
  position: fixed;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;

  ${media.lessThan('huge')`
    max-width: 50%;
   `}

  ${media.lessThan('medium')`
      display: none;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background-color: ${theme.background};
  `}
  ${media.greaterThan('huge')`
    grid-template-columns: minmax(320px, 800px) 1fr;
  `}

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`;

type BusinessCardWrapperProps = {
  show?: boolean;
};

const businessCardWrapperModifiers = {
  show: () => css`
    right: 25%;
    transform: translate(50%, -50%);
    opacity: 1;
    pointer-events: all;
  `,
};

export const BusinessCardWrapper = styled.div<BusinessCardWrapperProps>`
  ${({ show = false }) => css`
    position: fixed;
    right: 0;
    opacity: 0;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s linear, opacity 0.2s 0.05s linear;
    ${media.lessThan('medium')`
    display: none;
  `}

    ${show && businessCardWrapperModifiers.show()};
  `}
`;

type HeroWrapperProps = {
  bgUrl?: string;
  blur?: boolean;
  bgInherit?: string;
  bgCenter?: string;
};

const blurEffectModifiers = {
  blur: (theme: DefaultTheme, bgUrl: string) => css`
    &::before {
      background-image: url(${bgUrl});
      filter: blur(6px);
    }
  `,
};

export const KvImage = styled.div<HeroWrapperProps>`
  ${({
    theme,
    bgUrl,
    blur,
    bgInherit = THEME.Public.options?.backgroundSize,
    bgCenter = THEME.Public.options?.backgroundPosition,
  }) => css`
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 100%;
    width: 50vw;
    overflow: hidden;
    &::before {
      background-image: url(${bgUrl});
      background-repeat: no-repeat;
      background-size: ${bgInherit ?? 'cover'};
      background-position: ${bgCenter ?? '0% 0%'};
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transform: scale(1.02);
    }

    ${media.lessThan('medium')`
    display: none;
  `}/* ${blur && bgUrl && blurEffectModifiers.blur(theme, bgUrl)}; */
  `}
`;

export const BlurEffect = styled.video<HeroWrapperProps>`
  ${({
    theme,
    bgUrl = THEME.BackgroundAccreditationFallback,
    blur,
    bgInherit = THEME.Public.options?.backgroundSize,
    bgCenter = THEME.Public.options?.backgroundPosition,
  }) => css`
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    right: 0;
    height: 100%;
    /* width: 100%; */
    overflow: hidden;
    object-fit: cover;

    /* &::before {
      background-image: url(${bgUrl});
      background-repeat: no-repeat;
      background-size: ${bgInherit ?? 'cover'};
      background-position: ${bgCenter ?? '0% 0%'};
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      position: absolute;
      transform: scale(1.02);
    } */

    ${media.lessThan('medium')`
      display: none;
    `}

    ${blur && blurEffectModifiers.blur(theme, bgUrl)};
  `}
`;

export const WrapperLogo = styled.div`
  /* ${({ theme }) => css`
    margin-bottom: ${theme.spacing.lg};
  `} */
`;
