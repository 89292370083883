import { SubTitle } from 'components/structure';
import * as S from './SponsorItem.styles';

type SponsorType = 'small' | 'medium' | 'large';

export type SponsorsProps = {
  image: string;
  href?: string;
  width?: string;
};

export type SponsorItemProps = {
  category: string;
  type: SponsorType;
  sponsors: SponsorsProps[];
};

export const SponsorItem = ({ category, type, sponsors }: SponsorItemProps) => (
  <S.Wrapper>
    <SubTitle>{category}</SubTitle>
    <S.Banners>
      {sponsors.map(({ href, image, width }) => (
        <S.Container key={href} style={{}}>
          <S.Banner src={image} alt="sponsor image" type={type} width={width} />
        </S.Container>
      ))}
    </S.Banners>
  </S.Wrapper>
);
