import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Rules = styled.span`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    font-size: 12px;
    ${media.greaterThan('medium')`
        margin-bottom: 32px;
    `}
  `}
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
