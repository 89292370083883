import { createContext, useContext, useState, SetStateAction } from 'react';
import { LanguageStream } from 'contexts';
import { WithChildren } from 'interfaces/children';
import { StreamBackup } from 'interfaces/stream';

type ContextType = {
  streamsBackup: StreamBackup[];
  setStreamsBackup: (state: SetStateAction<StreamBackup[]>) => void;
  streamLanguages: LanguageStream[];
  setStreamLanguages: (language: LanguageStream[]) => void;
};

const defaultValue: ContextType = {
  streamsBackup: [
    {
      id: '',
      usingBackup: false,
      streamingDetails: [],
    },
  ],
  setStreamsBackup: () => undefined,
  streamLanguages: ['pt', 'libras'],
  setStreamLanguages: () => undefined,
};

export const StreamContext = createContext<ContextType>(defaultValue);
export const StreamProvider = ({ children }: WithChildren) => {
  const [streamsBackup, setStreamsBackup] = useState(
    defaultValue.streamsBackup,
  );
  const [streamLanguages, setStreamLanguages] = useState(
    defaultValue.streamLanguages,
  );
  return (
    <StreamContext.Provider
      value={{
        streamsBackup,
        setStreamsBackup,
        streamLanguages,
        setStreamLanguages,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export const useStreamContext = () => useContext(StreamContext);

StreamContext.displayName = 'StreamContext';
