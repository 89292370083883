import ReactPlayer from 'react-player';
import * as S from './vimeo.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function VimeoJS(props: any) {
  // const { videoRef } = useContext(PlayerContext) as any;

  return (
    <S.VideoContainer>
      <ReactPlayer
        url={props.url}
        width="100%"
        height="100%"
        style={{ aspectRatio: '16 / 9' }}
        controls={true}
        playing={true}
        playsinline={true}
      ></ReactPlayer>
    </S.VideoContainer>
  );
}
