/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import * as UserCardStyles from 'components/contexts/users/UserCard/UserCard.styles';
import { motion } from 'framer-motion';

const wrapperModifiers = {
  isStaff: () => css``,
};

export const LikeContainer = styled.div`
  display: flex;
  flex: 0 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  span {
    display: flex;
    margin: 0;
    background-color: #adadad;
    position: relative;
    left: -8px;
    color: black !important;
    font-weight: 700;
    padding-left: 0.75rem !important;
    padding-right: 0.5rem !important;
    border-radius: 0 20px 20px 0;
    z-index: 0;
  }
`;


export const LikeButton = styled.button<{liked: boolean}>`
${({  liked }) => css`
z-index: 10;
  background-color: ${(props) => (liked ? '#cf0e6b' : '#d0d0d0')};
  aspect-ratio: square;
  border-radius: 9999px;
  width: 22px;
  height: 22px;
  color: #ffffff !important;
  svg {
    * {
      color: white;
      fill: white;
    }
  }
`}
  

`;


export const MessageBox = styled.div<{ isStaff?: boolean }>`
  ${({ theme, isStaff }) => css`
    background-color: white;
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-template-rows: 32px 1fr;
    border-radius: 8px;
    padding: 0.5rem;
    column-gap: 0.5rem;
    width: 80%;
    ${isStaff &&
    css`
      outline: ${theme.primary} 1px solid;
    `};
  `}
`;

export const MessageWrapper = styled(motion.div)<{ isStaff: boolean }>`
  ${({ theme, isStaff }) => css`
    display: flex;
    grid-template-columns: 32px 1fr;
    grid-template-rows: 12px 1fr;
    grid-gap: 0 ${theme.spacing.xs};
    justify-items: flex-start;
    position: relative;
    /* flex: 1 1 40px; */
    padding: ${theme.spacing['2xs']} 4px 2px;
    /* background-color: ${theme.text.details}; */
    border-radius: 5px;
    /* width: 97%; */
    margin-left: 7px;

    &:not(:first-child) {
      margin-bottom: ${theme.spacing.xs};
    }
    ${isStaff && wrapperModifiers.isStaff()}
  `}
`;
export const BoxName = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 10px;
    align-items: center;
    text-transform: capitalize;
    color: ${theme.text.secondary};
  `}
`;

BoxName;

export const BoxMensagerSend = styled(motion.div)`
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  .likeContainerSent {
    display: flex;
    flex: 0 0;
    padding: 5px 10px;
    justify-content: flex-end !important;
  }
`;

type MessageSentProps = {
  isPending?: boolean;
  isReceived?: boolean;
  isStaff?: boolean;
};

export const Message = styled.p<MessageSentProps>`
  /* color: ${({ theme }) => theme.text.secondary}; */
  color: ${({ theme }) => theme.text.primary};
  font-family: ${({ theme }) => theme.typography.family.primary};
  font-size: ${({ theme }) => theme.typography.sizes.sm};
  line-height: ${({ theme }) => '1.2rem' ?? theme.typography.lineHeight.base};
  font-weight: 500;
  /* max-width: 97%; */
  min-width: 90%;
  word-wrap: break-word;
  /* word-break: break-all; */
  .likeContainer {
    display: flex;
    flex: 0 0;
  }

  // dentro da caixa com curtir a esquerda
  /* .likeContainerSent {
    display: flex;
    flex: 0 0;
    padding: 5px 0px;
    justify-content: flex-start !important;
  } */

  ${UserCardStyles.Wrapper} {
    visibility: visible;
    opacity: 1;
    /* transform: translate(-50%, -44%); */
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 9999 !important;

    ${media.lessThan('medium')`
        left: 0;
        top: -21px;
        right: 0;
        /* bottom: 100%; */
        transform: unset;
      `}

    ${UserCardStyles.Container} {
      transition: transform 0.3s ease-in-out;
      z-index: 9999;
    }
  }
`;

const messageSentModifiers = {
  received: (theme: DefaultTheme) => css`
    align-self: flex-start;
    background-color: white;
    color: ${theme.text.primary};
  `,
};

export const MessageSent = styled(Message)`
  ${({ theme, isPending, isReceived }) => css`
    display: flex;
    min-width: auto;
    color: ${theme.text.details};
    font-weight: normal;
    flex-direction: column;
    align-self: flex-end;
    background: ${theme.primary};
    border-radius: ${theme.shapes.borderRadius.sm};
    padding: ${theme.spacing.xs} 5px ${theme.spacing.xs} ${theme.spacing.sm};
    opacity: ${isPending ? 0.5 : 1};
    flex: 2 0 40px;
    margin: 0 6px;
    word-wrap: break-word !important;
    /* word-break: break-all !important; */
    /* letter-spacing: .05rem; */

    &:not(:first-child) {
      margin-bottom: ${theme.spacing.xs};
    }

    ${isReceived && messageSentModifiers.received(theme)};
  `}
`;

export const Date = styled.time`
  ${({ theme }) => css`
    /* margin-left: 5px; */
    margin-top: 0.5rem;
    display: flex;
    color: ${theme.text.secondary};
    align-content: center;
    align-self: center;
    justify-content: center;
    justify-self: center;
    font-family: ${theme.typography.family.primary};
    font-size: 12px;
    line-height: ${theme.typography.lineHeight.sm};
    text-align: left;
    align-self: center;
    font-weight: 700;
    opacity: 0.7;
  `}
`;
export const FirstBox = styled.small`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    font-size: 15px;
    align-items: start;
    gap: 10px;
    p {
      /* max-width: 90%; */
      font-weight: 500;
      word-wrap: break-word !important;
    }
  `}
`;

export const DateSent = styled.small`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${theme.text.secondary};
    // color: rgba(0,0,0, .5);
    font-weight: 700;
    font-family: ${theme.typography.family.primary};
    font-size: 12px;
    line-height: ${theme.typography.lineHeight.sm};
    text-align: left;
    margin-right: 1rem;
    opacity: 0.7;
  `}
`;
export const IconContainer = styled.div`
  box-sizing: border-box;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 99;
  svg {
    color: gray;
  }

  &:hover {
    transform: scale(1.3) rotate(-20deg);
    svg {
      /* fill: white; */
      color: red;
    }
    /* background-color: rgb(55, 95, 236); */
  }
  * {
    pointer-events: initial;
  }
  &:hover,
  * {
    cursor: pointer;
    pointer-events: initial;
  }
`;

export const IconContainerDeleteSend = styled.div`
  box-sizing: border-box;
  padding: 0.2rem;
  display: flex;
  position: relative;
  top: 20px;
  right: 20px;
  align-items: center;
  align-content: center;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 99;
  &:hover {
    background: #00000014;
  }
  * {
    pointer-events: initial;
  }
  &:hover,
  * {
    cursor: pointer;
    pointer-events: initial;
  }
  &:last-child {
    align-items: end;
    /* justify-content: end; */
  }
`;
export const Name = styled.small`
  color: ${({ theme }) => theme.text.primary};
  font-family: ${({ theme }) => theme.typography.family.primary};
  font-size: ${({ theme }) => theme.typography.sizes.xs};
  line-height: ${({ theme }) => theme.typography.lineHeight.sm};
  font-weight: 600;
  min-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 6px;
`;



export const LikeButtonSend = styled.div<any>`
  display: block;
  background-color: ${(props) => (props.liked ? ' #D6AA23 ' : '#d0d0d0')};
  /* padding: .5rem; */
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin-top: -15px; // fora da caixa, metade dentro
  /* margin-top: 0; // fora da caixa , totalmente fora */
  /* cursor: auto; */
  margin-right: 0.3rem;
  z-index: 25;

  svg {
    color: #ffffff !important;
    margin: auto;
    position: absolute;
    top: 25%;
    left: 25%;
  }
`;
export const test = styled.div``;

export const Mention = styled.strong`
  ${({ theme }) => css`
    /* background-color: #1288ff91; */
    color: ${theme.primary};
    /* line-height: 2rem; */
    /* padding: .05rem .2rem; */
    /* border-radius: 4px; */
    cursor: pointer;
  `}
`;

export const div = styled.a`
  word-break: break-all;
  text-decoration: underline;
`;
