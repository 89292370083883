import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { PopoverArrowDirection } from 'constants/enums';

type popoverProps = {
  show: boolean;
  bottom?: boolean;
  ref?: React.Ref<HTMLDivElement | null>;
  distance?: string;
  arrowDirection?: PopoverArrowDirection;
  placement?: 'bottom';
  colorScheme?: 'dark' | 'light';
};

export const popoverColorModifiers = {
  dark: () => css`
    background-color: ${'white'};
    color: ${'white'};

    &::after {
      background-color: ${'white'};
    }
  `,
  light: () => css`
    background-color: ${'white'};
    color: ${'black'};

    &::after {
      background-color: ${'white'};
    }
  `,
};

export const popoverPositionModifiers = {
  bottom: (distance: string) => css`
    top: calc(100% + ${distance});
    bottom: initial;
    left: initial;
    transform: translateX(-50%);

    &:after {
      display: none;
    }
  `,
};

export const arrowModifiers = {
  center: () => css`
    bottom: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
  `,
  left: () => css`
    bottom: -8px;
    left: 32px;
  `,
  right: () => css`
    bottom: -8px;
    right: 32px;
  `,
};

export const Container = styled.div`
  ${() => css`
    position: relative;
  `};
`;

export const Content = styled.div<popoverProps>`
  ${({
    theme,
    show,
    placement,
    distance = '30px',
    arrowDirection = 'center',
    colorScheme = 'dark',
  }) => css`
    background-color: ${theme.secondary};
    border: 1px solid ${theme.primary};
    border-radius: ${theme.spacing.sm};
    color: ${theme.text.primary};
    transition: opacity 0.3s linear;
    cursor: pointer;
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    max-width: 216px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    min-width: 190px;
    position: absolute;
    bottom: calc(100% + 22px);
    right: 0;
    z-index: 999;
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};
    ${media.lessThan('large')`
      bottom: calc(100% - 160px);
      right: -34px
    `}
    a,
    button {
      transition: color 0.3s linear;
      color: ${theme.text.primary};
      cursor: pointer;
    }

    a:hover,
    button:hover {
      color: ${theme.primary};
    }

    &::after {
      border: 1px solid ${theme.primary};
      border-bottom: none;
      border-right: none;
      background-color: ${theme.secondary};
      content: '';
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;

      transform: rotate(225deg);
      ${media.lessThan('large')`
         top:-8px;
         right: 42px;
         border: 1px solid ${theme.primary};

         border-top:none;
         border-left: none;

      `}
      ${arrowDirection && arrowModifiers[arrowDirection]()}
    }

    /* ${colorScheme && popoverColorModifiers[colorScheme]()}; */
    /* ${placement && popoverPositionModifiers[placement](distance)} */
  `}
`;

export const ContentLanguage = styled.div<popoverProps>`
  ${({
    theme,
    show,
    placement,
    distance = '22px',
    arrowDirection = 'center',
    colorScheme = 'dark',
  }) => css`
    background-color: ${theme.secondary};
    border: 1px solid ${theme.primary};
    border-radius: ${theme.spacing.sm};
    color: ${theme.text.primary};
    transition: opacity 0.3s linear;
    cursor: pointer;
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    max-width: 216px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    min-width: 190px;
    position: absolute;
    bottom: calc(100% + 15px);
    left: -65px;
    z-index: 999;
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};
    ${media.lessThan('large')`
      bottom: calc(100% - 122px);
    `}
    a,
    button {
      transition: color 0.3s linear;
      color: ${theme.text.primary};
      cursor: pointer;
    }

    a:hover,
    button:hover {
      color: ${theme.primary};
    }

    &::after {
      border: 1px solid ${theme.primary};
      border-bottom: none;
      border-right: none;
      background-color: ${theme.secondary};
      content: '';
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;

      transform: rotate(225deg);
      ${media.lessThan('large')`
      top:-8px;
      left:-40px;
         border: 1px solid ${theme.primary};

         border-top:none;
         border-left: none;

      `}
      ${arrowDirection && arrowModifiers[arrowDirection]()}
    }

    /* ${colorScheme && popoverColorModifiers[colorScheme]()}; */
    /* ${placement && popoverPositionModifiers[placement](distance)} */
  `}
`;

export const ContentConfiguration = styled.div<popoverProps>`
  ${({
    theme,
    show,
    placement,
    distance = '30px',
    arrowDirection = 'center',
    colorScheme = 'dark',
  }) => css`
    background-color: ${theme.secondary};
    border: 1px solid ${theme.primary};
    border-radius: ${theme.spacing.sm};
    color: ${theme.text.primary};
    transition: opacity 0.3s linear;
    cursor: pointer;
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    max-width: 216px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    min-width: 190px;
    position: absolute;
    bottom: calc(100% + 15px);
    left: 18px;
    z-index: 999;
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};
    ${media.lessThan('large')`
      bottom: calc(100% - 175px);
      left: -280px;
    `}
    a,
    button {
      transition: color 0.3s linear;
      color: ${theme.text.primary};
      cursor: pointer;
    }

    a:hover,
    button:hover {
      color: ${theme.primary};
    }

    &::after {
      border: 1px solid ${theme.primary};
      border-bottom: none;
      border-right: none;
      background-color: ${theme.secondary};
      content: '';
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;

      transform: rotate(225deg);
      ${media.lessThan('large')`
         top:-8px;
         left:302px;
         border: 1px solid ${theme.primary};

         border-top:none;
         border-left: none;

      `}
      ${arrowDirection && arrowModifiers[arrowDirection]()}
    }

    /* ${colorScheme && popoverColorModifiers[colorScheme]()}; */
    /* ${placement && popoverPositionModifiers[placement](distance)} */
  `}
`;
