import { getEventId } from 'lib/core/event';

export const useEnv = () => ({
  eventId: getEventId()!,
  googleMap: process.env.REACT_APP_GOOGLE_MAPS!,
  xAPIKey: process.env.REACT_APP_X_API_KEY,
  isDev: process.env.NODE_ENV === 'development',
  reactJWPlayer: process.env.REACT_APP_JW_PLAYER_URL,
  muxDataKey: process.env.REACT_APP_MUX_KEY,
  zendeskScriptUrl: process.env.REACT_APP_ZENDESK_CLIENT,
  zendeskKey: process.env.REACT_APP_ZENDESK_ID,
  webSocketUrl: process.env.REACT_APP_WEB_SOCKET_URL,
  googleRecaptcha: process.env.REACT_APP_GOOGLE_RECAPTCHA!,
  appTheme: process.env.REACT_APP_THEME,
  hotjar: process.env.REACT_APP_HOT_JAR_PATH!,
  cookieDomain: process.env.REACT_APP_DOMAIN_COOKIE!,
  apiBasePath: process.env.REACT_APP_API_BASE_PATH!,
  mkSSOUrl: process.env.REACT_APP_MK_SSO_URL!,
  favicon: process.env.REACT_APP_FAVICON,
});
