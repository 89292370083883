import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.background};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
    position: relative;
  `}
`;
export const Logo = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 150px;
`;

export const Title = styled.span`
  font-family: 'Bebas Neue';
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  text-align: center;
  // margin-bottom: 40px;
  margin-top: 0;
  color: #d51673;
  font-family: 'Bebas Neue';
  ${media.lessThan('medium')`
  line-height: 30px;
  font-size: 24px;
  margin-bottom: 20px;
    `}
`;

export const Subtitle = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 450px;
  color: #d51673;
  margin: 0;
`;
