/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Button, Paragraph } from 'components/structure';
import { FooterProps } from './Footer';

type ContainerProps = Pick<
  FooterProps,
  'hasInteractions' | 'show' | 'blockedScreen'
>;

const containerModifiers = {
  hasInteractions: () => css`
    max-width: calc(100% - ${'360px'});
  `,
};

export const Container = styled.footer<ContainerProps>`
  ${({ theme, show, hasInteractions, blockedScreen }) => css`
    background: ${theme.footerBackground};
    height: ${'80px'};
    padding: ${theme.spacing.sm} ${theme.spacing.lg};
    display: ${show ? 'grid' : 'none'};
    grid-template-columns: 8fr 2fr;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    transition: max-width 0.2s linear;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
    width: 100%;
    z-index: ${blockedScreen ? 9999 : theme.secondary};
    ${media.lessThan('huge')`
      padding: ${theme.spacing.sm} ${theme.spacing['lg']};
    `}

    ${hasInteractions && containerModifiers.hasInteractions()}
  `}
`;

export const IconButton = styled(Button)`
  ${({ theme }) => css`
    && {
      width: 100%;
      background-color: ${theme.primary};
      color: ${theme.secondary};
      min-height: 32px;
      height: 32px;
      margin-right: ${theme.spacing.md};
      border: 1px solid ${theme.primary};

      svg {
        color: ${theme.secondary};
        width: 18px;
        height: 18px;
        margin-right: ${theme.spacing.xs};
      }
    }
  `}
`;

export const AsideLeftContent = styled.div`
  display: flex;
  flex: 1;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
`;

export const Heading = styled.strong`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    font-size: ${theme.typography.sizes.sm};
    margin-left: ${theme.spacing.xs};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: ${theme.spacing.sm};

    ${Paragraph} {
      margin-bottom: 0;
    }
  `}
`;

export const AsideContent = styled.aside`
  ${({ theme }) => css`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: ${theme.spacing.xs};
    position: relative;
    gap: 40px;

    > button {
      border-radius: ${theme.shapes.borderRadius.lg};
      width: 100px;
      min-width: 100px;
      font-size: ${theme.typography.sizes.sm};
      font-weight: normal;
      height: 24px;
      border: 1px solid ${theme.primary};
      color: #fff;
    }
  `}
`;

export const Flex = styled.div`
  display: flex;
  align-items: baseline;
`;
