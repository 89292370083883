import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as ButtonGroup from 'components/structure/ButtonGroup/ButtonGroup.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    min-height: 100%;
    ${media.lessThan('medium')`
      padding-bottom: calc(${theme.spacing['2xl']} * 2);
    `} ${ButtonGroup.ButtonGroupWrapper} {
      margin-top: ${theme.spacing.md};
      height: 40px;
    }
  `}
`;

export const aboutName = styled.div`
  padding: 0 50px;
  margin-top: 215px;
  margin-left: -10px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  img {
    width: 150px;
    max-width: 150px;
  }
  @media (max-width: 1368px) {
    margin-top: 100px;
    margin-left: 0px;
    img {
      width: 110px;
      max-width: 110px;
    }
  }
  @media (max-width: 968px) {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: center;
    text-align: center;

    img {
      width: 100px;
      max-width: 100px;
    }
    margin-top: 20px;
    margin-left: 0px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.text.primary};
  `}
`;

export const BoxWarning = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Warning = styled.span`
  font-size: 12px;
`;
