import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { InteractionsOpenState } from 'constants/enums';
import { InteractionsProps } from './Interactions';

type WrapperProps = Pick<InteractionsProps, 'show'> & {
  openState?: InteractionsOpenState;
  playerHeight?: number;
};

const wrapperModifiers = {
  hide: () => css`
    transform: translateX(360px);
    overflow: initial;
  `,
  closed: () => css`
    transform: translateY(-${'56px'});
  `,
  open: () => css`
    position: static;
    transform: initial;
    flex: 1 1 0;
  `,
  full: () => css`
    height: calc(100% - ${'56px'});
    transform: translateY(-100%);
  `,
};

export const Wrapper = styled.aside<WrapperProps>`
  ${({ theme, show, openState }) => css`
    background-color: ${theme.secondary};
    grid-area: interactions;
    height: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    transition: transform 0.2s ease-in-out;
    transition-property: height;
    width: ${'360px'};
    overflow: hidden;

    ${media.lessThan('medium')`
      z-index: ${10};
    `}

    &,
    * {
      overscroll-behavior: contain;
    }

    ${media.lessThan('large')`
      left: 0;
      top: 100%;
      width: 100vw;

      ${show && openState && wrapperModifiers[openState]()};
    `}

    ${!show && wrapperModifiers.hide()};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${theme.secondary};
    background-color: ${theme.secondary};
    padding: ${theme.spacing.sm} ${theme.spacing.sm} 0;
    height: ${'80px'};
    width: 100%;
    position: relative;

    ${media.lessThan('large')`
      height: ${'56px'};
      padding: 20px ${theme.spacing.sm} 0;
    `}
  `}
`;

type IconWrapperProps = {
  hide: boolean;
  isOpen: boolean;
};

const iconWrapperModifiers = {
  hide: () => css`
    > svg {
      display: none;
    }
  `,
  isOpen: () => css`
    > svg {
      transition: transform 0.3s ease-in-out;
      transform: rotate(180deg);
    }
  `,
};

export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ hide, isOpen }) => css`
    display: none;

    ${media.lessThan('large')`
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
    `}

    ${isOpen && iconWrapperModifiers.isOpen()}
    ${hide && iconWrapperModifiers.hide()}
  `}
`;

const headerCollapseModifiers = {
  closed: (theme: DefaultTheme) => css`
    position: absolute;
    top: 10%;
    height: 40px;
    width: 40px;
    left: 0;
    background-color: ${theme.secondary};
    transform: translateY(60px) translateX(-40px);
    border-top-left-radius: ${theme.shapes.borderRadius.xs};
    border-bottom-left-radius: ${theme.shapes.borderRadius.xs};

    svg {
      transition: all 0.4s ease-out;
      transform: rotate(180deg);
    }
  `,
};

type HeaderCollapseProps = {
  closed: boolean;
};

export const HeaderCollapse = styled.button<HeaderCollapseProps>`
  ${({ theme, closed }) => css`
    // background-color: ${theme.secondary};
    background-color: rgba(0, 0, 0, 0.2);
    width: 25px;
    appearance: none;
    border: none;
    outline: none;
    transition: transform 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan('large')`
      display: none;
    `}

    ${closed && headerCollapseModifiers.closed(theme)}
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
`;

export const Body = styled.div`
  ${() => css`
    height: calc(100% - ${'80px'});
    position: relative;
    overflow: hidden;

    ${media.lessThan('large')`
      height: calc(100% - ${'56px'});
    `}
  `}
`;

type CommonProps = {
  active?: boolean;
};

export const Content = styled.div<CommonProps>`
  ${({ active }) => css`
    display: ${active ? 'block' : 'none'};
    height: 100%;
    width: 100%;
  `}
`;

export const ContainerError = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const BoxError = styled.div`
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
`;

export const TitleError = styled.h2`
  width: 100%;
  color: black;
`;
export const SubTitleError = styled.h4`
  width: 100%;
  color: black;
`;

export const DescriptionError = styled.p`
  width: 100%;
  color: black;
`;

const tabButtonModifiers = {
  active: (theme: DefaultTheme) => css`
    color: ${theme.primary};
    border-bottom-color: ${theme.primary};
  `,
};

export const TabButton = styled.button<CommonProps>`
  ${({ theme, active = false }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    user-select: none;
    appearance: none;
    border: none;
    border-bottom: 4px solid transparent;
    color: ${theme.text.secondary};
    transition: all 0.2s linear;
    outline: none;
    font-size: ${theme.typography.sizes.sm};
    line-height: ${theme.typography.lineHeight.base};
    text-align: center;

    &:not(:last-child) {
      margin-right: ${theme.spacing.sm};
    }

    svg {
      margin-bottom: ${theme.spacing['2xs']};
      height: 24px;
      width: 24px;
      color: currentColor;
    }

    ${media.lessThan('large')`
      padding-bottom: ${theme.spacing.xs};
      margin-top: 0;

      svg {
        display: none;
        margin-bottom: 0;
      }
    `}

    ${active && tabButtonModifiers.active(theme)};
  `}
`;
