/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import io from 'socket.io-client';
import { useEventById } from 'useCases/events';
import { useUserById } from 'useCases/users';
import {
  useEnv,
  useTranslate,
  useThemeOptions,
  useSession,
  useFetch,
} from 'hooks';
import { CountdownPage } from 'components/contexts/accreditation';
// import { Footer } from 'components/structure';
import * as CountdownTheme from 'styles/overrides/Countdown.theme';
import { User } from 'interfaces/user';
import { USERS } from 'constants/endpoints';
import { ProjectNames } from 'constants/enums';
import { ROUTES } from 'constants/urls';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL!;

export const Countdown = () => {
  // const navigate = useNavigate();
  const { id: userId } = useSession();
  const userById: any = useUserById(userId!);

  const isLoadingUser = useMemo(() => userById.isLoading, [userById.isLoading]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const errorMessage = useMemo(
    () => userById?.error?.message?.includes('401'),
    [userById?.error?.message],
  );
  const [eventSocket, setEventSocket] = useState<SocketIOClient.Socket>();
  const [error, setError] = useState(false);
  // const [cookies, setCookies, removeCookie] = useCookies(['Authorization']);
  const result = useFetch<{ user: User; accessToken: string }>(
    USERS.BY_ID(localStorage.getItem('id')!),
  );
  const { eventId } = useEnv();
  const { data, isLoading } = useEventById(eventId);

  useEffect(() => {
    if (result.data?.accessToken)
      setEventSocket(
        io.connect(SOCKET_URL, {
          path: '/event',
          auth: {
            accessToken: result.data?.accessToken,
          },
          transports: ['websocket'],
        }),
      );
  }, [result.data?.accessToken]);
  useEffect(() => {
    if (!isLoadingUser) {
      if (errorMessage) {
        localStorage.clear();
        document.cookie = '';
        setError(true);
      }
    }
  }, [errorMessage, isLoadingUser]);
  useEffect(() => {
    eventSocket?.on('change', (params: any) => {
      if (params.status === 'show-streams') {
        window.location.href = '/home';
      }
    });
  }, [eventSocket]);
  useEffect(() => {
    if (data?.event.status === 'show-streams') {
      window.location.href = '/home';
    }
  }
  , [data?.event.status]);
  const hasMemberGetMember = data?.event.hasMemberGetMember;
  const { themeName } = useThemeOptions();
  const translate = useTranslate();

  
  if (isLoading) {
    return null;
  }
  const themeNameParam =
    ProjectNames.BAYER === themeName || ProjectNames.VALE === themeName
      ? `.${themeName}`
      : '';



  if (error) {
    return <Navigate to={ROUTES.accreditation.base} />;
  }

  return (
    <>
      {/* {
        <Footer
          blockedScreen={true}
          avatar={userById.data?.user.avatar}
          userName={userById.data?.user.firstName}
          show={true}
          onChangeLanguage={(lang: LanguageStream) => setLanguageStream(lang)}
          isTranslationChange={true}
          language={languageStream}
          hasInteractions={false}
          hasNotification={false}
          hasMemberGetMember={hasMemberGetMember}
          menuItems={filteredMenuItems}
        />
      } */}

      <CountdownPage
        socialLinks={data?.event?.socialNetworks}
        eventDate={data?.event.dates[0] ?? ''}
        // hashtags={data?.event.hashtags} // hardcoded em accreditation.json
        hasFooterTitle={CountdownTheme.options?.hasFooterTitle}
        hasFooterDescription={CountdownTheme.options?.hasFooterDescription}
        footerTitle={translate(
          `accreditation.countdown${themeNameParam}.footerTitle`,
        )}
        hasMemberGetMember={hasMemberGetMember}
      />
    </>
  );
};
