import Slider from 'rc-slider';
import { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';
import {
  useImageCompression,
  usePortal,
  useTranslate,
  useCropImage,
  defaultAttributes,
} from 'hooks';
import { Button, Icon } from 'components/structure';
import * as S from './CropPhoto.styles';
// import 'rc-slider/assets/index.css';

export type CropPhotoProps = {
  imageToCrop: File;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  setValue: (file: File) => void;
};

export const CropPhoto = ({
  imageToCrop,
  showModal,
  setShowModal,
  setValue,
}: CropPhotoProps) => {
  const Portal = usePortal();
  const cropImage = useCropImage();
  const translate = useTranslate();
  const [cropArea, setCropArea] = useState(null);
  const [zoom, setZoom] = useState(1);
  const inputFile = useRef<HTMLInputElement>(null);
  const [imageAttr, setImageAttr] = useImageCompression(imageToCrop);

  const handleSliderChange = (newZoomValue: number) => {
    setImageAttr({ ...imageAttr, zoom: newZoomValue });
    setZoom(newZoomValue);
  };

  const handleOnCropChange = (location: Point) =>
    setImageAttr({ ...imageAttr, crop: location });

  const handleOnCropComplete = useCallback(
    (_, newCropArea) => setCropArea(newCropArea),
    [],
  );

  const saveCropImage = async () => {
    const croppedImage = await cropImage({
      imageToCrop,
      imageAttr,
      cropArea,
    });

    if (croppedImage) {
      setValue(croppedImage);
    }

    setShowModal(false);
  };

  const handleOnChange = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const image = fileEvent.target?.files?.item(0);

    if (image) {
      setImageAttr({
        ...imageAttr,
        image: URL.createObjectURL(image),
      });
    }
  };

  useEffect(() => {
    setImageAttr(defaultAttributes);
    setZoom(1);
  }, [setImageAttr, showModal]);

  return (
    <Portal>
      <S.Wrapper colorSchema="light" showModal={showModal}>
        <S.Content>
          <S.Header>
            <h4>{translate('labels.editPhoto')}</h4>
            <S.ButtonClose type="button" onClick={() => setShowModal(false)}>
              <Icon icon="IcClose" aria-label={translate('labels.close')} />
            </S.ButtonClose>
          </S.Header>

          <S.CanvasWrapper>
            <Cropper
              image={imageAttr.image}
              crop={imageAttr.crop}
              cropShape="round"
              zoom={imageAttr.zoom}
              aspect={imageAttr.aspect}
              onCropChange={handleOnCropChange}
              onCropComplete={handleOnCropComplete}
              cropSize={{ width: 210, height: 210 }}
              minZoom={1}
            />
            <S.SliderWrapper>
              <span>{translate('labels.zoom')}</span>
              <Slider
                min={1}
                max={10}
                value={zoom}
                onChange={handleSliderChange}
              />
            </S.SliderWrapper>
            <S.FloatingText>
              {translate('labels.dragToReposition')}
            </S.FloatingText>
          </S.CanvasWrapper>

          <S.Footer>
            <Button
              isSmall
              color="secondary"
              onClick={() => inputFile?.current?.click()}
            >
              {translate('labels.change')}
            </Button>
            <Button isSmall onClick={saveCropImage}>
              {translate('labels.save')}
            </Button>
          </S.Footer>
        </S.Content>
        <input
          onChange={handleOnChange}
          ref={inputFile}
          accept="image/*"
          type="file"
        />
      </S.Wrapper>
    </Portal>
  );
};
