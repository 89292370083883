import {
  createGlobalStyle,
  css,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components';
import media from 'styled-media-query';
import * as ConversationStyles from 'components/contexts/interactions/Chat/Conversation/ConversationMessage/ConversationMessage.styles';
import * as PrivateStyles from 'components/contexts/routes/Private/Private.styles';
import * as NavbarStyles from 'components/structure/Navbar/Navbar.styles';
import { Players } from 'constants/enums';

type GlobalStyleProps = {
  blockScroll?: boolean;
  hasStream?: boolean;
  isSafari?: boolean;
};

const globalStylesModifiers = {
  withStream: (theme: DefaultTheme) => css`
    -webkit-overflow-scrolling: touch;
    #root {
      overflow: hidden;
    }

    ${media.lessThan('large')`
      overflow: hidden;

      ${NavbarStyles.Wrapper} {
        position: static;
        flex 0 0 ${'56px'};
      }

      ${PrivateStyles.Wrapper} {
        display: flex;
        flex-direction: column;
        min-height: -webkit-fill-available;


        ${PrivateStyles.OutletWrapper} {
          margin-top: 0;
          padding-bottom: 0;
        }
      }

      ${ConversationStyles.InputWrapper} {
        &:focus-within {
          background-color: ${theme.primary};
        }
      }

      #root {
        height: 100%;
      }
    `}
  `,
};

const GlobalStyles: GlobalStyleComponent<
  GlobalStyleProps,
  DefaultTheme
> = createGlobalStyle`
  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    ${({ theme, blockScroll, hasStream = false }) => css`
      font-family: ${theme.typography.family.primary};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-scrolling: touch;
      height: 100%;
      overflow: ${blockScroll ? 'hidden' : 'initial'};

      ${hasStream && globalStylesModifiers.withStream(theme)};
    `}
  }

  a {
    text-decoration: none;
    color: white;
  }

  button {
    appearance: none;
    border: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
  }

 #${Players.PIP} {
    > .jw-aspect,
    .jw-icon-fullscreen {
      display: none;
    }

    ${media.lessThan('medium')`
      .jw-wrapper {
        bottom: 72px;
        top: auto;
      }
    `}
  }
`;

export default GlobalStyles;
