/* eslint-disable   @typescript-eslint/no-explicit-any */
import { useRef, useState, useEffect } from 'react';
// import ReactJWPlayer from 'react-jw-player';
// import ReactPlayer from 'react-player';
import { useLocation } from 'react-router';
import { useStreams } from 'useCases/streams';
// import { useEnv } from 'hooks';
import { dayInterval } from 'lib/contexts/date';
import { Skeleton } from 'pages/streams/Stream/Stream.skeleton';
import { useMiniPlayerContext } from 'contexts';
// import { PlayerContext } from 'contexts/PlayerContext';
import { FloatingHeart } from 'components/contexts/interactions/FloatingHeart/FloatingHeart';
// import Plyr from 'components/structure/Plyr';
// import PlyrJs from 'components/structure/Plyr';
import VideoJS from 'components/structure/VideoJS';
import VimeoJS from 'components/structure/Vimeo';
import { Players, VideoPlayers } from 'constants/enums';
// import { IcPip } from 'assets/icons';
import * as S from './VirtualRoom.styles';

export type VirtualProps = {
  url: string;
  children?: JSX.Element | null | undefined | boolean;
  watchAgainPlayer: boolean;
  videoPlayerType?: VideoPlayers;
};

// const PIP_CONFIG = {
// 	icon: IcPip,
// 	tooltipText: 'Picture In Picture',
// 	id: 'pip',
// };

const UNMUTE_WARNING_DURATION = 5000;

// type VideoPresentationModeType = 'inline' | 'picture-in-picture';

// interface IHTMLVideoElement {
// 	requestPictureInPicture: () => Promise<void>;
// 	disablePictureInPicture: boolean;
// 	webkitPresentationMode?: VideoPresentationModeType;
// 	webkitSetPresentationMode: (mode: VideoPresentationModeType) => void;
// 	webkitSupportsPresentationMode: boolean;
// }
const videoTypes = {
  m3u8: 'application/x-mpegURL',
  vimeo: 'video/vimeo',
  mp4: 'video/mp4',
};
export const VirtualRoom = ({
  url,
  children,
  videoPlayerType,
}: VirtualProps) => {
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    loop: false,
    sources: [
      {
        src: url, //
        type: videoTypes.m3u8, // Changes according to video type
      },
    ],
  };

  // const { reactJWPlayer } = useEnv();
  const playerRef = useRef<HTMLDivElement>(null);
  const [isMounted] = useState(false);
  const [isReady] = useState(false);
  const { setUrl } = useMiniPlayerContext();
  const [hasUnmuteWarning, setHasUnmuteWarning] = useState(false);
  const { pathname } = useLocation();
  const streamId = pathname.split('/')[2];
  const { data } = useStreams(dayInterval());
  const currentStream = data?.streams.find((s) => s.id === streamId);
  const REACTIONS = currentStream?.reactions?.active;
  const POSITION_REACTIONS = currentStream?.reactions?.position;
  // const { video } = useContext(PlayerContext) as any;
  // const onReady = useCallback(() => {
  // 	setIsMounted(false);
  // 	setIsReady(true);
  // }, []);

  // const time = parseInt(localStorage.getItem('video-time')!);

  // const playlist = [
  // 	{
  // 		file: url,
  // 		starttime: time | 0,
  // 	},
  // ];
  // const enterPictureInPicture = useCallback(
  // 	async (videoElement: IHTMLVideoElement) => {
  // 		try {
  // 			if (videoElement.webkitSupportsPresentationMode) {
  // 				videoElement.webkitSetPresentationMode('picture-in-picture');
  // 			} else {
  // 				await videoElement.requestPictureInPicture();
  // 			}
  // 		} catch (error) {
  // 			console.error(error);
  // 		}
  // 	},
  // 	[],
  // );
  // const onPip = useCallback(async () => {
  //   const videoHls = document.createElement('video');
  //   videoHls.hidden = true;
  //   document.body.appendChild(videoHls);

  //   let hls: Hls;
  //   if (videoHls) {
  //     const video = videoHls;

  //     if (video.canPlayType('application/vnd.apple.mpegurl')) {
  //       // This will run in safari, where HLS is supported natively
  //       video.src = url;
  //       video.addEventListener('loadedmetadata', () => {
  //         enterPictureInPicture(video as unknown as IHTMLVideoElement);
  //       });
  //     } else if (Hls.isSupported()) {
  //       // This will run in all other modern browsers
  //       hls = new Hls();
  //       hls.loadSource(url);
  //       hls.attachMedia(video);
  //       video.play();
  //       video.addEventListener('loadedmetadata', () => {
  //         enterPictureInPicture(video as unknown as IHTMLVideoElement);
  //       });
  //     }
  //   }

  //   videoHls.addEventListener('leavepictureinpicture', () => {
  //     videoHls.remove();
  //     navigate(ROUTES.stream.getLink('id', streamId));
  //   });

  //   navigate(ROUTES.app.getLink('home'));
  // }, [enterPictureInPicture, navigate, streamId, url]);

  /*  useEffect(() => {
	   if (videoPlayerType === VideoPlayers.JWPLAYER) {
		 if (typeof window.jwplayer === 'function' && isReady) {
		   const player = window.jwplayer(Players.MAIN);
		   if (!isSafari) {
			 const { icon, tooltipText, id } = PIP_CONFIG;
			 player.addButton(icon, tooltipText, onPip, id);
		   }
		   const hasPlayerHeight = player.getHeight();
		   if (hasPlayerHeight) setPlayerHeight(hasPlayerHeight);
		 }
	   }
	 }, [isReady, setPlayerHeight, videoPlayerType, onPip]); */

  useEffect(() => {
    if (hasUnmuteWarning) {
      const timeout = setTimeout(() => {
        setHasUnmuteWarning(false);
      }, UNMUTE_WARNING_DURATION);

      return () => clearTimeout(timeout);
    }
  }, [hasUnmuteWarning]);

  useEffect(() => {
    if (videoPlayerType === VideoPlayers.JWPLAYER) {
      if (!isReady || typeof window.jwplayer !== 'function') return;
      const player = window.jwplayer(Players.MAIN);
      const isMuted = player.getMute();
      if (isMuted) setHasUnmuteWarning(true);
    }
  }, [isReady, videoPlayerType]);

  useEffect(() => {
    setUrl(url);
  }, [setUrl, url]);

  const videoPlayers = {
    youtube: (
      <S.VideoWrapper>
        <S.VideoContainer>
          <iframe
            src={url}
            allow="autoplay; fullscreen; encrypted-media"
            frameBorder="0"
            allowFullScreen
            scrolling="no"
          ></iframe>
        </S.VideoContainer>
      </S.VideoWrapper>
    ),
    m3u8: <VideoJS key={url} options={videoJsOptions} />,
    mp4: <VideoJS key={url} options={videoJsOptions} />,
    vimeo: <VimeoJS url={url} />,
  };

  function handleIdentifyService(url: string) {
    if (/youtube|youtu\.be/.test(url)) {
      return 'youtube';
    }
    if (/vimeo/.test(url)) {
      videoJsOptions.sources[0].type = videoTypes.vimeo;
      return 'vimeo';
    }
    if (/m3u8/.test(url)) {
      videoJsOptions.sources[0].type = videoTypes.m3u8;
      return 'm3u8';
    }

    if (/mp4/.test(url)) {
      videoJsOptions.sources[0].type = videoTypes.mp4;
      videoJsOptions.loop = true;
      return 'mp4';
    }

    return 'm3u8';
  }

  return (
    <>
      <S.Wrapper ref={playerRef} hide={isMounted}>
        {isMounted && <Skeleton />}
        {url && videoPlayers[handleIdentifyService(url)]}

        {children}
        {/* <S.PlayerVideo>
          {hasUnmuteWarning && (
						<S.UnmuteLabel>{translate('stream.unMutePlayer')}</S.UnmuteLabel>
						)}
						{videoPlayerType && videoPlayers[videoPlayerType]}
						{children}
					</S.PlayerVideo> */}
      </S.Wrapper>
      {REACTIONS && (
        <S.Interaction position={POSITION_REACTIONS}>
          <FloatingHeart position={POSITION_REACTIONS} />
        </S.Interaction>
      )}
    </>
  );
};
