import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Heading } from 'components/structure';

export const Wrapper = styled.div`
  ${() => css`
    padding: 0px 15%;
    ${media.lessThan('medium')`
      padding: 0px;
    `}
  `}
`;

export const QuestionsWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    background: ${theme.text.details};
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: ${theme.shapes.borderRadius.md};
    padding: ${theme.spacing.lg};
    ${media.lessThan('medium')`
      height: auto;
    `}
    > ${Heading} {
      font-size: ${theme.typography.sizes.lg};
    }
  `}
`;

export const QuestionSearchWrap = styled.div`
  ${({ theme }) => css`
    max-width: 368px;
    ${media.lessThan('medium')`
      width: 100%;
      *{
        font-size: ${theme.typography.sizes.sm};
      }
  `}
  `}
`;

export const ErrorWrapper = styled.div`
  padding: 10%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const QuestionsWrap = styled.div`
  display: flex;
  flex-flow: column;
`;
type QuestionCollapsePros = {
  open: boolean;
};

export const QuestionCollapse = styled.div<Pick<QuestionCollapsePros, 'open'>>`
  ${({ theme, open }) => css`
    max-height: auto;
    background-color: ${theme.input.background};
    margin-top: ${theme.spacing.lg};
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    border-radius: ${theme.shapes.borderRadius.xs};
    cursor: pointer;
    svg {
      color: ${theme.text.secondary};
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
      transform: ${open ? 'rotate(180deg)' : ''};
      transition: transform 0.3s ease-in-out;
    }
  `}
`;
export const QuestionCollapseWrap = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;

    > span {
      color: ${theme.text.secondary};
      padding-right: ${theme.spacing.xs};

      ${media.lessThan('medium')`
        padding-right: ${theme.spacing.sm};
      `}
    }
  `}
`;

export const QuestionText = styled.div<Pick<QuestionCollapsePros, 'open'>>`
  ${({ open, theme }) => css`
    display: ${open ? '' : 'none'};
    padding-bottom: ${theme.spacing.sm};
    padding-top: ${theme.spacing.sm};
    color: ${theme.text.secondary};
  `}
`;
