import { memo } from 'react';
import { Option } from 'components/form';
import { User } from 'interfaces/user';
import { BusinessCardForm as BusinessCardFormBayer } from './Bayer/BusinessCardForm';
import { BusinessCardForm as BusinessCardFormDefault } from './Default/BusinessCardForm';

export type BusinessCardFormProps = {
  onSubmit: (user: User) => void;
  isPending?: boolean;
  countries?: Option[];
  profiles?: Option[];
  languages?: Option[];
  firstDayWorkshops?: Option[];
  secondDayWorkshops?: Option[];
  thirdDayWorkshops?: Option[];
  defaultValues?: User;
  hasLinkedInField?: boolean;
  hasSupportButton?: boolean;
  hasMemberGetMember?: boolean;
  hasPasswordStrength?: boolean;
  hasCountry?: boolean;
  hasJobArea?: boolean;
};

const BusinessCardFormComponent = ({
  onSubmit,
  isPending,
  countries = [],
  languages = [],
  profiles = [],
  firstDayWorkshops = [],
  secondDayWorkshops = [],
  thirdDayWorkshops = [],
  defaultValues,
  hasLinkedInField = true,
  hasSupportButton = true,
  hasMemberGetMember = false,
  hasPasswordStrength = true,
  hasCountry = false,
  hasJobArea = false,
}: BusinessCardFormProps) => {
	const emailDomain = defaultValues?.email?.split('@')[1]

  if (['bayer.com', 'bayer.com.br'].includes(emailDomain || '')) {
    return (
      <BusinessCardFormBayer
        countries={countries}
        profiles={profiles}
        onSubmit={onSubmit}
        isPending={isPending}
        languages={languages}
        firstDayWorkshops={firstDayWorkshops}
        secondDayWorkshops={secondDayWorkshops}
        thirdDayWorkshops={thirdDayWorkshops}
        defaultValues={defaultValues}
      />
    );
  }

  return (
    <BusinessCardFormDefault
      countries={countries}
      profiles={profiles}
      onSubmit={onSubmit}
      isPending={isPending}
      languages={languages}
      defaultValues={defaultValues}
      hasLinkedInField={hasLinkedInField}
      // TODO: migrar prop showSupportButton para hasSupportButton
      showSupportButton={hasSupportButton}
      hasMemberGetMember={hasMemberGetMember}
      hasPasswordStrength={hasPasswordStrength}
      hasCountry={hasCountry}
      hasJobArea={hasJobArea}
    />
  );
};

export const BusinessCardForm =  memo(BusinessCardFormComponent);
