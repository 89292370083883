import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useUserById } from 'useCases/users';
// import { ROUTES } from 'constants/urls';
// import { useSession } from './useSession';

dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

type Countdown = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export function getTimeRemaining(endtime: string) {
  const date = dayjs(endtime);
  const diff = dayjs.duration(date.diff(new Date()));
  const daysWithMonths = diff.months() * 30 + diff.days();
  return {
    days: daysWithMonths,
    hours: diff.hours(),
    minutes: diff.minutes(),
    seconds: diff.seconds(),
  };
}

export const todayIsTheDay = (date = '') =>
  date && dayjs().isSameOrAfter(dayjs(date));

export const useCountdown = (futureDate: string) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<Countdown>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (todayIsTheDay(futureDate)) {
      // navigate(ROUTES.app.getLink('home'));
    }
  }, [futureDate, navigate]);

  useEffect(() => {
    const dateInterval = setInterval(() => {
      setCountdown(getTimeRemaining(futureDate));
    }, 1000);

    return () => clearInterval(dateInterval);
  }, [futureDate]);

  return countdown;
};
