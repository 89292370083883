import * as S from './EventCardImage.styles';

export type EventCardImageProps = {
  title?: string;
  thumbnail?: string;
  isWatchAgain?: boolean;
  id?: string;
};

export const EventCardImage = ({
  title,
  thumbnail,
  isWatchAgain,
  // id,
}: EventCardImageProps) => 

  // const watchAgain = (id: string | undefined) => {
  //   navigate(ROUTES.stream.getLink('id', id));
  // };

  (
    <>
      {isWatchAgain ? (
        <S.Wrapper>
          <S.PictureWrapper>
            <img
              src={thumbnail}
              alt={title}

              // onClick={() => watchAgain(id)}
            />
          </S.PictureWrapper>
        </S.Wrapper>
      ) : (
        <S.Wrapper>
          <S.PictureWrapper>
            <img src={thumbnail} alt={title} />
          </S.PictureWrapper>
        </S.Wrapper>
      )}
    </>
  )
;
