import { InputHTMLAttributes, forwardRef } from 'react';
import { HelperText } from 'components/form';
import { Icon } from 'components/structure';
import * as S from './TextField.styles';

export type TextFieldProps = {
  label?: string;
  type?: string;
  error?: boolean;
  helperText?: string;
  icon?: React.ReactElement;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ error, label, name, type, helperText, icon: IconProp, ...props }, ref) => (
      <S.Container >
        <S.InputWrapper hasError={error} disabled={props.disabled} readOnly={props.readOnly}>
          <S.Field type={type} ref={ref} id={name} name={name} {...props} />
          {!!label && <S.Label htmlFor={name}>{label}</S.Label>}
          {error ? (
            <S.IconContainer>
              <Icon
                color={'red'}
                icon="IcAlert"
              />
            </S.IconContainer>
          ) : (
            props.readOnly ? <S.IconContainer><Icon icon='IcLock' color='white' /></S.IconContainer> : !!IconProp && <S.Icon>{IconProp}</S.Icon>
          )}
        </S.InputWrapper>
        {helperText && <HelperText error={error}>{helperText}</HelperText>}
      </S.Container>
    ),
);
