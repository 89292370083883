import styled from 'styled-components';

export const VideoContainer = styled.div`
	z-index: 0;
  @media (min-width: 1550px) {
    width: 90%;
    margin: auto;
  }
  @media screen and (max-width: 1550px) and (min-width: 1400px) {
    width: auto;
    height: 70vh;
    aspect-ratio: 16/9;
    margin: 0 auto;
  }
  @media screen and (max-width: 1400px) and (min-width: 1024px) {
    width: auto;
    height: 63vh;
    aspect-ratio: 16/9;
    margin: 0 auto;
  }
`;
