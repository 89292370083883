/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, memo } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useSession } from 'hooks';
import { useInteractionsContext } from 'contexts';
import {
  Conversation,
  ConversationProps,
  LatestMessagesReturn,
} from 'components/contexts/interactions';
import { Skeleton } from 'components/contexts/interactions/Chat/Conversation/Conversation.skeleton';
import { InteractionsOpenState } from 'constants/enums';
import * as S from './ChatRoom.styles';

export type UserChat = {
  userIdTest?: string;
  id: string;
  avatar?: string;
  userId?: string;
  name: string;
  company?: string;
  email?: string;
  state?: string;
  country?: string;
  message?: string;
  received?: boolean;
  icon?: JSX.Element;
  hasUnreadMessage?: boolean;
  createdAt?: string;
  isSended?: boolean;
  isPrivate?: boolean;
  isStaff?: boolean;
  mentions?: any[];
  likes?: {
    content: Array<any>;
    count: number;
  };
  handleSendReaction?: any;
  handleReceiveReaction?: any;
  realTimeLikes?: any;
  setLikes?: any;
  streamId?: any;
  eventId?: string;
};

export type ChatRoomProps = Pick<
  ConversationProps,
  'handleSendMessage' | 'onClick' | 'error'
> & {
  room?: UserChat;
  isLoading?: boolean;
  show?: boolean;
  useLatestMessages: (roomId: string) => LatestMessagesReturn;
  handleJoinPrivateChannel: (
    userId: string,
    startedByUser?: boolean | undefined,
    cb?: (() => void) | undefined,
  ) => void;
  handleDeleteMessage: (id: string) => SocketIOClient.Socket;
  setReachedLimit: (value: boolean) => void;
  reachedLimit: boolean;
  setError: Dispatch<SetStateAction<string>>;
};

export const ChatRoom = memo(
  ({
    room,
    onClick,
    show = false,
    isLoading,
    error,
    setError,
    handleSendMessage,
    useLatestMessages,
    handleJoinPrivateChannel,
    handleDeleteMessage,
    setReachedLimit,
    reachedLimit,
  }: ChatRoomProps) => {
    const { openState } = useInteractionsContext();
    // const { pathname } = useLocation();
    // const { id: userId } = useSession()
    // const streamId = pathname.split('/')[2];

    
    if (isLoading) {
      return (
        <S.Wrapper show={show}>
          <Skeleton />
        </S.Wrapper>
      );
    }

    return (
      <S.Wrapper show={show}>
        {!!room?.id && (
          <Conversation
            setReachedLimit={setReachedLimit}
            reachedLimit={reachedLimit}
            isOpen={
              show &&
              [InteractionsOpenState.open, InteractionsOpenState.full].includes(
                openState,
              )
            }
            room={room}
            handleSendMessage={handleSendMessage}
            useLatestMessages={useLatestMessages}
            handleJoinPrivateChannel={handleJoinPrivateChannel}
            handleDeleteMessage={handleDeleteMessage}
            onClick={onClick}
            error={error}
            setError={setError}
          />
        )}
      </S.Wrapper>
    );
  },
  (prev, curr) => {
    return prev.isLoading === curr.isLoading && prev.show === curr.show && prev.room?.id === curr.room?.id
  },
);
