import styled from 'styled-components';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;

  ${TypographyStyles.SubTitle} {
    margin-bottom: 0;
  }

  input {
    display: none;
  }
`;
export const BoxInsertYourImage = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    margin-bottom: 8px;
  }
  button {
    width: max-content;
  }
`;
