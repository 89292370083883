import React, { useRef, useEffect, useState, useCallback, useMemo, useContext } from 'react';
import io from 'socket.io-client';
import { useUserById } from 'useCases/users';
import { useEvent } from './events';
import { cat } from 'shelljs';
import { SocketContext, useSocketContext } from 'contexts';
import { useSocketListener } from 'hooks';

type UseReactionsProps = {
  streamId: string;
};

type HandleSendReactionProps = {
  userId: string | undefined;
  eventId: string;
  reactType: string;
};

type HandleReceiveReactionProps = {
  id: string;
  createdAt?: string;
  eventId: string;
  userId: string;
  updatedAt?: string;
  reactType: string;
};

const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL!;
const REACTIONS = '/reactions';



export const useReactions = ({ streamId }: UseReactionsProps) => {
  const {reactionSocket: socket, setReactionSocket} = useSocketContext()
  const { data } = useUserById(localStorage.getItem('id') || '');
  const [reactions, setReactions] = useState<HandleReceiveReactionProps[]>([]);
  const [isDocumentHidden, setIsDocumentHidden] = useState(false);
  
  useEffect(() => {
    if (data?.accessToken){
      const socket =  io.connect(SOCKET_URL, {
        forceNew: true,
        path: REACTIONS,
        auth: {
          accessToken: data?.accessToken,
        },
        secure: SOCKET_URL.includes('https') ? undefined : false,
        transports: ['websocket'],
      });
      socket.on('connect', () => {
        socket.emit('join_stream', {
          streamId: streamId,
        });
      })
      setReactionSocket(socket);
    }

      
      return () => {
        socket?.disconnect()
      }
  }
  , [data?.accessToken]);

  
  const createReaction = useCallback(
    ({
      id,
      createdAt,
      eventId,
      userId,
      updatedAt,
      reactType,
    }: HandleReceiveReactionProps) => {
      setReactions((prevState) => {
            if(prevState.length < 10) {
              return [
                ...prevState,
                { id, createdAt, eventId, userId, updatedAt, reactType },
              ]
            }
            return [...prevState]
          });
    }, [setReactions])
  



  const handleSendReaction = useCallback(
    ({ userId, eventId, reactType }: HandleSendReactionProps) => {
      if (userId) {
        socket?.emit('new_reaction', {
          userId: userId,
          eventId: eventId,
          streamId: streamId,
          reactType: reactType,
          emit: true,
        });
        // createReaction({
        //   id: new Date().getTime().toString(),
        //   eventId,
        //   userId,
        //   reactType,
        // });
        }
    },
    [/* canLikeTimeout, */ createReaction, streamId, socket],
  );

  const handleVisibilityChange = useCallback(async () => {
    if(document.hidden || !document.hasFocus()) {
      setReactions([]);
    }
    setIsDocumentHidden(document.hidden || !document.hasFocus());
  }, []);

  useEffect(() => {
    const handleVisibilityChange = async (e: any) => {
      setReactions([])
      // setIsDocumentHidden(document.hidden || !document.hasFocus());
    };
    // window.addEventListener('focusout', handleVisibilityChange)
    // window.addEventListener('focusin', handleVisibilityChange)
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  // useSocketListener(socket, 'new_reaction', createReaction)
  useSocketListener(socket, 'share_reaction', createReaction)
  useSocketListener(socket, 'share_to_all', createReaction)
  // useSocketListener(socket, 'connected', () => {
  // })
  
  return {
    handleSendReaction,
    reactions,
    setReactions,
    // canLikeTimeout,
    // setCanLikeTimeout,
  };
};
