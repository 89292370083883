import { useEffect } from 'react';
import { mutate } from 'swr';
import { v4 } from 'uuid';
import { useFetch, useSocketListener } from 'hooks';
import { getURLWithParams } from 'lib/contexts/urls';
import { useSocketContext, useStreamContext } from 'contexts';
import { StreamBackup, Stream, StreamState } from 'interfaces/stream';
import { STREAMS } from 'constants/endpoints';
import { StreamEvents } from 'constants/enums';

type UseStreamsParams = {
  startDate: string;
  endDate: string;
};

type StreamNotification = {
  id: string;
  status: string;
};

export const useStreams = (params: UseStreamsParams) =>
  useFetch<{ streams: Stream[] }>(getURLWithParams(STREAMS.ROOT, params), {}, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    // refreshInterval: 30000,
  });

export const useStreamById = (id: string, headers = {}) =>
  useFetch<{ stream: Stream }>(getURLWithParams(STREAMS.BY_ID(id), headers), {
    headers,
  },
  {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    // refreshInterval: 30000,
  }
  );

export const useStream = (streamId: string, params = {}) => {
  const { surveySocket: socket } = useSocketContext();
  const { setStreamsBackup } = useStreamContext();

  const useJoinToStream = (
    socket: SocketIOClient.Socket,
    streamId?: string,
  ) => {
    useEffect(() => {
      const sessionId = v4();
      localStorage.setItem('sessionId', sessionId);
      socket.emit(StreamEvents.JOIN, {
        streamId,
        userId: localStorage.getItem('id'),
        sessionId,
      });
      window.addEventListener('beforeunload', () => {
        socket.emit(StreamEvents.LEAVEPAGE, {
          streamId,
          userId: localStorage.getItem('id'),
          sessionId,
        });
        localStorage.removeItem('sessionId');
      });
      return () => {
        socket.emit(StreamEvents.LEAVEPAGE, {
          streamId,
          userId: localStorage.getItem('id'),
          sessionId,
        });
        localStorage.removeItem('sessionId');
      };
    }, [socket, streamId]);
  };

  const useBeforeunload = (
    socket: SocketIOClient.Socket,
    streamId?: string,
  ) => {
    useEffect(() => {
      window.addEventListener('beforeunload', () => {
        socket.emit(StreamEvents.LEAVEPAGE, {
          streamId,
          userId: localStorage.getItem('id'),
          sessionId: localStorage.getItem('sessionId'),
        });
        localStorage.removeItem('sessionId');
      });
    }, [socket, streamId]);
  };

  const onUsingBackup = (data: StreamBackup) => {
    setStreamsBackup([data]);
  };

  const onFinished = (data: StreamNotification) => {
    mutate(
      getURLWithParams(STREAMS.BY_ID(data.id), params),
      (currentStream: { stream: Stream }) => ({
        stream: {
          ...currentStream.stream,
          state: StreamState.Finished,
        },
      }),
      false,
    );
  };

  const onConnect = () =>
    console.info(
      `%cStream socket connected`,
      `color: #06c16a; font-weight: bold;`,
    );

  useSocketListener(socket, StreamEvents.CONNECTED, onConnect);
  useSocketListener(socket, StreamEvents.CONNECTED, onConnect);
  useJoinToStream(socket, streamId);
  useSocketListener(socket, StreamEvents.FINISHED, onFinished);
  useSocketListener(socket, StreamEvents.USING_BACKUP, onUsingBackup);
  useBeforeunload(socket, streamId);
};
