import styled from 'styled-components';
import media from 'styled-media-query';

export const VideoContainer = styled.div`
  display: flex;
  width: auto;
  max-width: 100%;
  height: 95%;
  aspect-ratio: 16/9;
  margin: 0 auto;

  ${media.lessThan('medium')`
    width: 100%;
    height: 260px;

    @media screen and (orientation: landscape) {
      height: 260px; 
    }
  `}

  ${media.between('medium', 'large')`
    width: 100%;
    height: 400px;
  `}
`;
