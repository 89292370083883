import { AnimatedIcon } from "components/structure";
import { SetStateAction, useCallback } from "react";

interface IReactionsSpawnContainer {
  streamId: string;
  position?: string;
  reactions: any[];
  setReactions: React.Dispatch<SetStateAction<any[]>>;
}

export function ReactionsSpawnContainer({streamId, position, reactions, setReactions}: IReactionsSpawnContainer) {



  const removeReactions = useCallback(
    () => {
        setReactions((state) => [...state.slice(1)]);
    },
    [setReactions],
  );
  return <>
        {reactions?.map((value, index) => (
        <AnimatedIcon
          reactType={value.reactType}
          key={value.id}
          onAnimationComplete={() => removeReactions()}
          position={position}
        />
      ))}
  </>
}