import { useEffect } from 'react';
import { useConfirmRegistration } from 'useCases/auth';
import { Skeleton } from './ConfirmRegistration.skeleton';

export const ConfirmRegistration = () => {
  const { handleConfirmRegistration } = useConfirmRegistration();

  useEffect(() => {
    handleConfirmRegistration();
  }, [handleConfirmRegistration]);

  return <Skeleton />;
};
