/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState, useEffect, memo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useIsChatInputFocused } from 'hooks/useIsChatInputFocused';
import { MessageField } from 'components/form';
import { Icon } from 'components/structure';
import { CHAT_FIELD_MAX_LENGTH } from 'constants/enums';
import * as S from './ConversationMessage.styles';



// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConversationInputFieldProps = {
  showError: boolean;
  error?: string;
  focus: boolean;
	blurRef?: any;
  inputRef?: HTMLInputElement | null;
  handleSubmit: () => void;
  placeholder: string;
} & Pick<UseFormMethods, 'register' | 'setValue' | 'getValues'>;
// const inputRef = useRef<HTMLInputElement | null>();
export const ConversationMessage = memo(({
  showError,
  error,
  handleSubmit,
  focus,
  placeholder,
  register,
  setValue,
	blurRef,
  inputRef,
  getValues,
}: ConversationInputFieldProps) => {
  const mentionInputRef = useRef<HTMLInputElement | null>();
  const [mentionsValue, setMentionsValue] = useState([]);
  // const [isFocused, setIsFocused] = useState(false);
	const {isFocused, setIsFocused} = useIsChatInputFocused()

  useEffect(() => {
    const handleFocus = () => {

      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
			if(blurRef?.current){
				(blurRef as any)?.current.focus()
			}
    };

    const inputElement = (inputRef as any)?.current;

    inputElement.addEventListener('focus', handleFocus);
    inputElement.addEventListener('blur', handleBlur);

    return () => {
      inputElement.removeEventListener('focus', handleFocus);
      inputElement.removeEventListener('blur', handleBlur);
    };
  }, [blurRef, inputRef, setIsFocused]);
  return (
    <>
		<S.InputWrapper focused={isFocused}>
			<S.GlobalStyles focus={isFocused} />
      <S.MessageRejected show={showError}>
        <Icon icon="IcAlert" />
        <span>{error}</span>
      </S.MessageRejected>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <MessageField
          isDisabled={showError}
          autoFocus={focus}
          inputRef={inputRef}
          name="message"
          setValue={setValue}
          getValues={getValues}
          placeholder={placeholder}
          register={register}
          maxLength={CHAT_FIELD_MAX_LENGTH}
        />

      </form>
    </S.InputWrapper>
		</>
  );
},
() => true,
)