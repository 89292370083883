import { useSignIn } from 'useCases/auth';
import { useEvent } from 'useCases/events';
import { SignInForm } from 'components/contexts/authentication';

const SignIn = () => {
  const { data: event } = useEvent();
  const {
    handleAuthenticate,
    isPending,
    showRecaptcha,
    recaptchaRef,
  } = useSignIn();
  return (
    <SignInForm
      onSubmit={handleAuthenticate}
      isPending={isPending}
      showSupportButton={event?.event.zendesk?.active}
      showRecaptcha={showRecaptcha}
      recaptchaRef={recaptchaRef}
    />
  );
};

export default SignIn;
