import { useEffect, useState } from 'react';

export const useScript = (url: string, id: string, isEnabled?: boolean) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!url || !isEnabled) {
      return;
    }
    const script = document.createElement('script');
    if (id) script.id = id;

    script.src = url;
    script.async = true;

    const onScriptLoad = () => {
      setLoaded(true);
    };

    const onScriptError = () => {
      script.remove();

      setLoaded(false);
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    return () => {
      if (document?.body) {
        document.body.removeChild(script);
      }
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [url, id, isEnabled]);

  return loaded;
};
