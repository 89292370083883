/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Profiler
} from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { responseInterface } from 'swr';
import * as Yup from 'yup';
import { dispatchGAEvent } from 'services/google';
import { useLikes } from 'useCases/likes';
import {
  useInfiniteScroll,
  usePortal,
  useSession,
  useTransformMessages,
  useTranslate,
} from 'hooks';
import { useSocketContext } from 'contexts';
import {
  Message,
  PlenaryIcon,
  UserChat,
} from 'components/contexts/interactions';
import { Chip } from 'components/contexts/users';
import { Icon, Spinner } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { LastEvaluatedKey, LatestMessages } from 'interfaces/chat';
import {
  GA_EVENTS,
  InteractionsTabs,
  ChatTypes,
  CHAT_FIELD_MAX_LENGTH,
} from 'constants/enums';
import { Skeleton } from './Conversation.skeleton';
import * as S from './Conversation.styles';
import { ConversationMessage } from './ConversationMessage/ConversationMessage';
import whydidyourender from '@welldone-software/why-did-you-render';

export type LatestMessagesReturn = responseInterface<
  LatestMessages,
  unknown
> & {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  loadMoreMessages: () => void;
  lastEvaluatedKey?: LastEvaluatedKey;
};

// interface UseCard {
//   name: string;
//   userId?: string;
//   avatar?: string;
//   email?: string;
//   country?: any;
//   company?: string;
//   state?: string;
// }

interface deleteMessage {
  id: string;
}

type FormData = {
  message: string;
  mentions?: string;
};

export type ConversationProps = {
  room: UserChat;
  onClick?: () => void;
  handleSendMessage: (
    message: string,
    mentions?: any[],
    customMentions?: any[],
  ) => void;
  useLatestMessages: (roomId: string) => LatestMessagesReturn;
  handleJoinPrivateChannel: (
    userId: string,
    startedByUser?: boolean | undefined,
    cb?: (() => void) | undefined,
  ) => void;
  handleDeleteMessage: (id: string) => SocketIOClient.Socket;
  error?: string;
  setError: Dispatch<SetStateAction<string>>;
  isOpen?: boolean;
  reachedLimit?: boolean;
  setReachedLimit?: (value: boolean) => void;
};

export const schema = Yup.object().shape({
  uuid: Yup.string().max(CHAT_FIELD_MAX_LENGTH),
});


export const Conversation = memo(
  ({
    room,
    error,
    onClick,
    setError,
    handleSendMessage,
    useLatestMessages,
    isOpen,
    // handleJoinPrivateChannel,
    handleDeleteMessage,
    setReachedLimit,
  }: ConversationProps) => {
    const translate = useTranslate();
    const theme = useTheme();
    const [deleteMessage, setDeleteMessage] = useState<deleteMessage>({
      id: '',
    });
    const [slowMode, setSlowMode] = useState(false);
    const blurRef = useRef(null);
    const inputRef = useRef<HTMLInputElement | null>();
    const { chatSocket } = useSocketContext();
    const messageRef = useRef<any>(null);
    const device = useMemo(() => localStorage.getItem('checkDevice'), []);
    const streamId = useLocation().pathname.split('/')[2];
    const maxMessageListLength = 35;
    // const Portal = usePortal();
    const { id: userIdSession } = useSession();
    const {
      isLoading,
      isLoadingMore,
      hasMore,
      loadMoreMessages,
      data,
    } = useLatestMessages(room.id)

    const messages = useTransformMessages(data?.messages, maxMessageListLength);
    const likesFromMessages = useMemo(
      () => messages?.flatMap((message) => message?.likes?.content),
      [messages],
    );
    const { handleSubmit, register, setValue, getValues } = useForm<FormData>({
      resolver: yupResolver(schema),
    });

    const { handleSendReaction, likes: realTimeLikes, setLikes } = useLikes(
      streamId,
      likesFromMessages,
    );


    // const lastMessageRef = useInfiniteScroll(
    //   isLoadingMore,
    //   hasMore,
    //   loadMoreMessages,
    // );

    const onSubmit = (data: FormData) => {
      (messageRef as any).current?.scrollIntoView();
      if (device === 'iPhone' && inputRef?.current) {
        inputRef.current.blur();
      }
      if (slowMode) {
        setError('Você deve esperar antes de enviar a próxima mensagem');
        return;
      }
      if (data.message) {

        handleSendMessage(data.message);
        // setSlowMode(true);
        setValue('message', '');
      }
      dispatchGAEvent({
        category: EventCategory.Chat,
        action: EventAction.click,
        label: GA_EVENTS.chat.labels.sendMessage,
      });
    };

    // const handlePrivateChat = async (id: string) => {
    //   // handleOpenPrivateChat(id);
    //   setRoom(undefined);
    //   // await new Promise(resolve => setTimeout(resolve, 0));
    //   setTimeout(() => {
    //     handleJoinPrivateChannel(id);
    //   }, 0);
    //   // setShowRoom(true);
    // };

    const DeleteMessageByStaff = (id: string) => {
      handleDeleteMessage(id);
      setDeleteMessage({ id: '' });
    };

    // const getMessageRef = useCallback(
    //   (position: number) => {
    //     if (messages.length <= maxMessageListLength) {
    //       setReachedLimit && setReachedLimit(true);
    //       return position === messages?.length - 1 ? lastMessageRef : null;
    //     }
    //     return null;
    //   },
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   [lastMessageRef, messages?.length],
    // );

    useEffect(() => {
      (async () => {
        if (error) {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setError('');
        }
      })();
    }, [error, setError]);

    // useEffect(() => {
    //   console.log(2);
    //   (async () => {
    //     if (slowMode) {
    //       await new Promise((resolve) => setTimeout(resolve, 1500));
    //       setSlowMode(false);
    //     }
    //   })();
    // }, [slowMode]);

    useEffect(() => {
      if (isIOS) {
        const handleVisibilityChange = () => {
          if (!document.hidden) {
            window.location.reload();
          }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () =>
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange,
          );
      }
    }, [chatSocket.connected]);


    
    if (isLoading) {
      return <Skeleton />;
    }

    return (
      <>
        <S.Header>
          <S.ReturnButton type="button" onClick={onClick}>
            <Icon color={theme.primary} icon="IcChevron" />
          </S.ReturnButton>
          <Chip
            id={room.id}
            name={room.name}
            avatar={room.avatar}
            icon={room.isPrivate ? null : <PlenaryIcon size="small" />}
            isCenter
          />
        </S.Header>
    
        {deleteMessage?.id != '' && (
          <S.Modal>
            <S.DeleteMessage>
              <S.ButtonClose
                type="button"
                onClick={() => setDeleteMessage({ id: '' })}
              >
                <Icon
                  icon="IcClose"
                  width="16px"
                  aria-label={translate('labels.close')}
                />
              </S.ButtonClose>
              <S.tittle>
                Você tem certeza que deseja deletar a messagem ?
              </S.tittle>

              <S.BoxButton>
                <button
                  className="buttonDelete button"
                  onClick={() => DeleteMessageByStaff(deleteMessage?.id)}
                >
                  Deletar
                </button>
                <button
                  className="buttonCancel button"
                  onClick={() => setDeleteMessage({ id: '' })}
                >
                  Cancelar
                </button>
              </S.BoxButton>

              {/* {deleteMessage.id} */}
            </S.DeleteMessage>
          </S.Modal>
        )}

        <S.Messages device={device || ''}>
          {messages
            // ?.slice(0, !userData?.user?.role?.includes('staff') ? 70 : Infinity)
            .map(
              (
                {
                  avatar,
                  name,
                  message,
                  country,
                  company,
                  email,
                  state,
                  id,
                  received,
                  isSended,
                  isStaff,
                  createdAt,
                  likes,
                  userId,
                },
                index,
              ) => (
                <Message
                  handleDeleteMessage={handleDeleteMessage}
                  handleOpenPrivateChat={() => userId}
                  setTab={() => InteractionsTabs.chat}
                  // ref={getMessageRef(index)}
                  messageRef={index === 0 ? messageRef : null}
                  id={id}
                  key={id}
                  userIdTest={userId}
                  avatar={avatar}
                  name={name}
                  email={email}
                  company={company}
                  country={country}
                  state={state}
                  createdAt={createdAt}
                  message={message}
                  received={received}
                  isSended={isSended}
                  isPrivate={room.id.startsWith(ChatTypes.PRIVATE)}
                  isStaff={isStaff}
                  likes={likes}
                  currentUserId={userIdSession}
                  handleSendReaction={handleSendReaction}
                  realTimeLikes={realTimeLikes?.filter(
                    (l: any) => l?.messageId == id,
                  )}
                  setLikes={setLikes}
                  userId={userId}
                  streamId={streamId}
                  isLastMessage={index == 0}
                />
              ),
            )}
          {isLoadingMore && (
            <S.SpinnerWrapper>
              <Spinner />
            </S.SpinnerWrapper>
          )}
        </S.Messages>
        {isMobile && isOpen && (
          <>
            {/* <Portal> */}
              <ConversationMessage
                showError={(error?.length || 0) > 0}
                error={
                  error?.length
                    ? error
                    : 'Você deve esperar antes de enviar a próxima mensagem'
                }
                blurRef={blurRef}
                inputRef={inputRef as any}
                focus={false}
                handleSubmit={handleSubmit(onSubmit)}
                setValue={setValue}
                getValues={getValues}
                placeholder={translate('interactions.typeHere')}
                register={register}
              />
            {/* </Portal> */}
            <input ref={blurRef} hidden />
          </>
        )}
        {!isMobile && (
          <ConversationMessage
            showError={(error?.length || 0) > 0}
            error={
              error?.length
                ? error
                : 'Você deve esperar antes de enviar a próxima mensagem'
            }
            focus={!isMobile}
            handleSubmit={handleSubmit(onSubmit)}
            setValue={setValue}
            getValues={getValues}
            placeholder={translate('interactions.typeHere')}
            register={register}
            inputRef={inputRef as any}
          />
        )}
        <input ref={blurRef} hidden />

      </>
    );
  },
  (prev, curr) => {
    return true
  }
);
