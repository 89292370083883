/* eslint-disable @typescript-eslint/no-explicit-any */

import { forwardRef, memo, Ref } from 'react';
import { useSession } from 'hooks';
import { UserChat } from 'components/contexts/interactions';
import { Avatar } from 'components/contexts/users';
import { Icon } from 'components/structure';
import { InteractionsTabs } from 'constants/enums';
import { IcHeart } from 'assets/icons';
import { IcNewLike } from 'assets/icons/interactions';
import * as S from './Message.styles';

// interface UseCard {
//   name: string;
//   userId?: string;
//   avatar?: string;
//   email?: string;
//   country?: any;
//   company?: string;
//   state?: string;
// }

export type UserCardProps = {
  handleOpenPrivateChat: (id: string) => void;
  setTab: (tab: InteractionsTabs) => void;
  isChatDisabled?: boolean;
};

const MessageComponent = forwardRef(
  (
    {
      // userIdTest,
      avatar,
      name,
      // company,
      // email,
      // state,
      // country,
      message,
      id,
      received,
      createdAt,
      isSended = true,
      isPrivate = false,
      isStaff = false,
      messageRef,
      mentions,
      handleSendReaction,
      realTimeLikes,
      userId,
      streamId,
      currentUserId,
      handleDeleteMessage,
    }: UserChat & {
      handleDeleteMessage(id: string): any;
      isLastMessage: boolean;
      messageRef: any;
      currentUserId?: string;
    } & UserCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const data = useSession();
    const reactionIcon: string = 'like' as string;
    // const messageRef = useRef(null);
    const formattedTime =
      !!createdAt &&
      new Date(createdAt).toLocaleTimeString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });

    let formattedMessage = message;
    if (
      formattedMessage?.includes('https://') ||
      formattedMessage?.includes('http://') ||
      formattedMessage?.includes('www')
    ) {
      formattedMessage = formattedMessage.split(' ').map((word) => {
        if (word?.startsWith('https://') || word?.startsWith('http://')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'black',
              }}
              href={word}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {' '}
              <i>{word}</i>
            </a>
          );
        }
        if (word?.startsWith('www')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'black',
              }}
              href={`https://${word}`}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {' '}
              <i>{word}</i>
            </a>
          );
        }
        return <> {word}</>;
      }) as any;
    }



    // Enviada
    if (isPrivate || !received) {
      return (
        <S.BoxMensagerSend ref={ref || messageRef} key={id}>
          <S.MessageSent isPending={!isSended} isReceived={received}>
            <S.FirstBox>
              <p>{formattedMessage}</p>

              {data?.role === 'staff' && (
                <S.IconContainer onClick={() => handleDeleteMessage(id)}>
                  <Icon
                    icon="IcTrash"
                    style={{
                      cursor: 'pointer',
                    }}
                    width="16px"
                    height="16px"
                    color="red"
                  />
                </S.IconContainer>
              )}
            </S.FirstBox>
            <S.DateSent>{formattedTime}</S.DateSent>
          </S.MessageSent>

        </S.BoxMensagerSend>
      );
    }

 
    const splittedMessage = message?.split(' ');

    let messageElement;
    if (mentions && mentions.length) {
      messageElement = splittedMessage?.map((word, index) => {
        const currentMention = mentions ? mentions[0] : '';
        word = word.replace(/[@]/g, '');
        if (word?.startsWith('mention-') && mentions && mentions?.length) {
          const mentionIndex: string = (word.split('-') as any).at(-1);
          const mentionUserName =
            mentions[Number(mentionIndex)]?.username ||
            mentions[Number(mentionIndex)]?.name;

          return (
            <>
              <S.Mention

              >
                {' '}
                @{mentionUserName}
              </S.Mention>
            </>
          );
        }
        if (
          splittedMessage[index - 1] + ' ' + word ==
          currentMention?.username
        ) {
          return <></>;
        }
        if (word.startsWith('https://')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'black',
              }}
              href={word}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {word}
            </a>
          );
        }
        return <> {word}</>;
      });
    } else {
      messageElement = message?.split(' ').map((word, index) => {
        if (word.startsWith('https://') || word.startsWith('http://')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'black',
              }}
              href={word}
              target={'_blank'}
              rel={'noreferrer'}
              key={`word-${index}`}
            >
              {' '}
              {word}
            </a>
          );
        }
        if (word.startsWith('www')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'black',
              }}
              href={`https://${word}`}
              target={'_blank'}
              rel={'noreferrer'}
              key={`word-wwww-${index}`}
            >
              {' '}
              {word}
            </a>
          );
        }
        return <> {word}</>;
      });
    }
    // Recebida
    return (
      <S.MessageWrapper
        // initial={{ scale: .5, opacity: 0 }}
        // animate={{ scale: 1, opacity: 1 }}
        key={id}
        ref={ref || messageRef}
        isStaff={isStaff}
      >
        <S.MessageBox isStaff={isStaff}>
          <Avatar
            src={avatar}
            alt={name}
            size="small"
          />

          <S.Name>
            <S.BoxName>{name}</S.BoxName>
            {data?.role === 'staff' && (
              <S.IconContainer onClick={() => handleDeleteMessage(id)}>
                <Icon
                  icon="IcTrash"
                  style={{
                    cursor: 'pointer',
                  }}
                  width="16px"
                  height="16px"
                  color="red"
                />
              </S.IconContainer>
            )}
          </S.Name>
          <S.Message ref={messageRef} key={`message-${id}`}>
            <>{messageElement}</>
            <S.LikeContainer>
              <S.Date>{formattedTime}</S.Date>
            </S.LikeContainer>
          </S.Message>
        </S.MessageBox>
      </S.MessageWrapper>
    );
  },
);

export const Message = memo(
  MessageComponent,
  (prev, curr) => prev.realTimeLikes.length === curr.realTimeLikes.length  
);
