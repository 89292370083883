/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslate } from 'hooks';
import { isBayer, isValidUrl } from 'lib/contexts';
import { getEventThemeName } from 'lib/core/event';
import { ThemeCustomProvider } from 'contexts';
import { Avatar } from 'components/contexts/users';
import { Button, Icon } from 'components/structure';
import * as BusinessCardTheme from 'styles/overrides/BusinessCard.theme';
import { User } from 'interfaces/user';
import { ProjectNames } from 'constants/enums';
import * as S from './UserCard.styles';

export type UserCardProps = {
  user: User;
  handleCloseCard?: () => void;
  handlePrivateChat?: () => void;
  isChatDisabled?: boolean;
  forwardRef?: React.Ref<HTMLDivElement>;
};

export const UserCard = ({
  user: { avatar, name, email, linkedInUrl, profile, allowChat },
  handleCloseCard,
  handlePrivateChat,
  isChatDisabled = false,
  forwardRef,
}: UserCardProps) => {
  const translate = useTranslate();
  const isBayerEvent = getEventThemeName() === ProjectNames.BAYER;
  const isBayerEmail = isBayer(email ?? '');

  const showName = (name: string | undefined) => {
    if (name && name?.length > 14) {
      const nameSeparado = name?.split(' ');
      if (
        (
          nameSeparado[0] +
          ' ' +
          nameSeparado[1].slice(0, 1) +
          '. ' +
          nameSeparado[2]
        ).length > 14
      ) {
        const newName =
          nameSeparado[0] + ' ' + nameSeparado[1].slice(0, 1) + '.';
        return newName;
      } else {
        return (
          nameSeparado[0] +
          ' ' +
          nameSeparado[1].slice(0, 1) +
          '. ' +
          nameSeparado[nameSeparado.length - 1]
        );
      }
    }
    return name;
  };

  return (
    <ThemeCustomProvider {...BusinessCardTheme}>
      <S.Wrapper>
        <S.Container ref={forwardRef}>
          <S.Content>
            <Avatar src={avatar} alt={name} />

            <S.ContentInfos>
              <strong>{showName(name)}</strong>
              {/* {country !== 'undefined' && (
                <S.UserInfoSmall>{country}</S.UserInfoSmall>
              )} */}

              <S.UserInfoSmall>
                <strong style={{ color: 'red', opacity: 0.5 }}>
                  {email?.includes('@bayer') ? 'Bayer' : ''}
                </strong>
                {isBayerEvent && isBayerEmail ? 'Bayer' : profile?.value}
              </S.UserInfoSmall>
              {BusinessCardTheme.options?.hasIcons && (
                <S.SocialsContainer>
                  {linkedInUrl && isValidUrl(linkedInUrl) && (
                    <a
                      href={linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="IcLinkedinSmall" />
                    </a>
                  )}
                  {/* {email && (
                    <a
                      href={`mailto:${email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="IcEmail" />
                    </a>
                  )} */}
                </S.SocialsContainer>
              )}
            </S.ContentInfos>
            <S.IconWrapper onClick={handleCloseCard}>
              <Icon width="15px" height="15px" icon="IcClose" />
            </S.IconWrapper>
          </S.Content>
          {!isChatDisabled && (
            <Button isSmall fullWidth onClick={handlePrivateChat} disabled={!allowChat}>
              {translate('interactions.talk')}
            </Button>
          )}
        </S.Container>
      </S.Wrapper>
    </ThemeCustomProvider>
  );
};
