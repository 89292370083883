import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { Heading, Paragraph } from 'components/structure';
import { PlenaryCardProps } from './PlenaryCard';

type TimeProps = {
  hasDot?: boolean;
};

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 200px;
    background: ${theme.secondary};
    border: 1px solid ${theme.secondary};
    border-radius: ${theme.shapes.borderRadius.md};
    padding: ${theme.spacing.sm};

    &:not(:first-child) {
      margin-top: ${theme.spacing.sm};
    }

    ${Anchor} {
      display: grid;
      grid-template-columns: 220px 1fr;
      grid-template-rows: repeat(2, auto) 1fr;
      grid-column-gap: ${theme.spacing.sm};
    }

    ${media.lessThan('medium')`
      height: auto;

      ${Anchor} {
        display: flex;
        flex-direction: column;
      }
    `}

    * {
      grid-column: 2/-1;
    }

    ${LiveLabel} {
      top: 8px;
      left: 8px;
      max-width: 180px;
    }

    ${Title} {
      color: ${theme.text.secondary};
      font-weight: bold;
      font-size: ${theme.typography.sizes.base};
      line-height: ${theme.typography.lineHeight.base};
      margin: 0;
    }

    ${Excerpt} {
      color: ${theme.text.secondary};
      font-size: ${theme.typography.sizes.xs};
      line-height: ${theme.typography.lineHeight.sm};
      margin: 0;
    }

    ${Time} {
      display: none;
    }

    ${PictureWrapper} {
      grid-column: 1/1;
      grid-row: 1/-1;
    }
  `,
};

export const Wrapper = styled.div<Pick<PlenaryCardProps, 'small'>>`
  ${({ theme, small }) => css`
    ${small && wrapperModifiers.small(theme)};
  `}
`;

export const ContainerTitleSchedule = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.xs};
    margin-top: ${theme.spacing.sm};
    ${media.lessThan('medium')`
      flex-direction: column-reverse;
      align-items: start;
      gap: 16px;
    `}
  `}
`;

export const ButtonSchedule = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #034168;
  background-color: transparent;
  font-size: 16px;
  color: #034168;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 40px;
  cursor: pointer;
`;

export const Anchor = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  height: 100%;
  overflow: hidden;

  ${media.lessThan('medium')`
    height: auto;
  `}
`;

export const LiveLabel = styled.span`
  ${({ theme }) => css`
    background-color: ${'red'};
    border-radius: 3px;
    color: ${theme.text.primary};
    font-size: ${theme.typography.sizes.sm};
    font-family: ${theme.typography.family.primary};
    line-height: ${theme.typography.lineHeight.sm};
    font-weight: bold;
    padding: ${theme.spacing['2xs']} 10px;
    left: 16px;
    position: absolute;
    top: 5%;
    ${media.lessThan('medium')`
      top: 12px;
      left: 12px;
    `}
  `}
`;

type SoonLabelProps = {
  redFlag?: boolean;
};

export const SoonLabel = styled(LiveLabel)<SoonLabelProps>`
  ${({ theme, redFlag = false }) => css`
    color: ${theme.secondary};
    background-color: ${redFlag ? 'red' : theme.text.secondary};
  `}
`;

export const PictureWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    max-height: 460px;

    ${media.greaterThan('huge')`
      max-height: 615px;
      height: auto;
    `}
  }
`;

export const Title = styled(Heading)`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.lg};
    line-height: ${theme.typography.lineHeight.md};
    margin-bottom: 0;
    margin-top: 0;
    color: ${theme.primary};
  `}
`;

export const timeModifiers = {
  withDot: (theme: DefaultTheme) => css`
    padding-left: 12px;
    &::before {
      background-color: ${theme.primary};
      border-radius: 50%;
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      width: 6px;
    }
  `,
};

export const Time = styled.span<TimeProps>`
  ${({ theme, hasDot }) => css`
    position: relative;
    display: flex;
    font-size: ${theme.typography.sizes.sm};
    color: ${theme.text.secondary};
    ${hasDot && timeModifiers.withDot(theme)}
  `}
`;

export const Excerpt = styled(Paragraph)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xs};
    font-size: ${theme.typography.sizes.base};
    font-weight: 400;
    line-height: ${theme.typography.lineHeight.base};
    width: 50%;
    ${media.lessThan('medium')`

		width: 100%;
		`}
  `}
`;
