/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useSocketListener = (
  socket?: SocketIOClient.Socket,
  event?: string,
  callback?: any,
) => {
  useEffect(() => {
    if(socket){
      socket.on(event!, callback);

    return () => {
      socket.off(event!, callback);
    };
    }
  }, [callback, event, socket]);
};
