import React, { useCallback, useMemo, useRef } from 'react';
import * as S from './AnimatedIcon.styles';

type AnimatedIconProps = {
  onAnimationComplete?: () => void;
  reactType: string;
  position?: string;
};

// -18.053991113335954 -14.966061313575338 13.819806760681736 -10.329694175791582 -9.428601594616616 20.35645418529255

// 17.724155684863597 6.5328472463843354 13.69351234031086 -22.695600559793228 -36.42013679512085 18.870489061438093

// 21.389588670509617 23.887825937089218 -20.088966481223654 -16.38443641856202 -49.180913408832744 -35.124038669630416

// 3.785466093166601 0.26472145851354867 -21.487247793638215 14.200604019268347 -22.04359504823707 14.920135945698746
// 14.131956644046781 -28.659063523427513 -26.281799545639885 -5.820551652837491 -27.50514901491342 -45.35620990889602

// -0.46529554682728147 -11.658104499617124 14.846999644104201 11.48902711768708 -40.40336657917814 -11.46033912816916

// -12.357029953678788 -0.3031316938467583 -11.124720319540096 1.0838607468714585 12.617079885431401 -0.956818764588327

// -16.824034892897178 15.130137393260426 -34.62515981247498 19.142084385291028 -40.72093422043522 -42.887310104572435

// 13.929982757799173 -43.36141181860932 8.215773919731333 19.53103067085261 -0.7902308356869554 13.216140066014006

// 16.281821130165355 -27.6920249800733 -46.1555936466182 12.039649407865404 -4.335014496635573 -2.137620567428449

export const AnimatedIconComponent = ({
  onAnimationComplete,
  reactType,
  position,
}: AnimatedIconProps) => {
  const getRandomNumber = useCallback(
    (min: number, max: number) => Math.random() * (max - min) + min,
    [],
  );
  const randomXValues = [
    [
      -18.053991113335954,
      -14.966061313575338,
      13.819806760681736,
      -10.329694175791582,
      -9.428601594616616,
      20.35645418529255,
    ],
    [
      17.724155684863597,
      6.5328472463843354,
      13.69351234031086,
      -22.695600559793228,
      -36.42013679512085,
      18.870489061438093,
    ],
    [
      21.389588670509617,
      23.887825937089218,
      -20.088966481223654,
      -16.38443641856202,
      -49.180913408832744,
      -35.124038669630416,
    ],
    [
      3.785466093166601,
      0.26472145851354867,
      -21.487247793638215,
      14.200604019268347,
      -22.04359504823707,
      14.920135945698746,
    ],
    [
      14.131956644046781,
      -28.659063523427513,
      -26.281799545639885,
      -5.820551652837491,
      -27.50514901491342,
      -45.35620990889602,
    ],
    [
      -0.46529554682728147,
      -11.658104499617124,
      14.846999644104201,
      11.48902711768708,
      -40.40336657917814,
      -11.46033912816916,
    ],
    [
      -12.357029953678788,
      -0.3031316938467583,
      -11.124720319540096,
      1.0838607468714585,
      12.617079885431401,
      -0.956818764588327,
    ],
    [
      -16.824034892897178,
      15.130137393260426,
      -34.62515981247498,
      19.142084385291028,
      -40.72093422043522,
      -42.887310104572435,
    ],
    [
      13.929982757799173,
      -43.36141181860932,
      8.215773919731333,
      19.53103067085261,
      -0.7902308356869554,
      13.216140066014006,
    ],
    [
      16.281821130165355,
      -27.6920249800733,
      -46.1555936466182,
      12.039649407865404,
      -4.335014496635573,
      -2.137620567428449,
    ],
  ];
  const ref = useRef<HTMLDivElement>(null);
  const animate = useMemo(
    () => ({
      opacity: [0, 0.9, 0],
      y: [getRandomNumber(0, 100), -200],
      scale: [0, 1.2, 1, 1, 1, 1, 0],
      x: randomXValues[Math.floor(getRandomNumber(0, 9.9))],
    }),
    [getRandomNumber],
  );


  return (
    <S.MotionDiv
      ref={ref}
      animate={animate}
      initial={{
        scale: 0,
        opacity: 0,
      }}
      transition={{
        duration: 2,
        scale: {
          type: 'spring',
          stiffness: 260,
          damping: 20,
          duration: 2,
        },
      }}
      style={{ position: 'absolute', right: 190, bottom: '20%' }}
      onAnimationComplete={
        onAnimationComplete ||
        (() => {
          ref.current && ref.current?.remove();
        })
      }
      position={position}
    >
      <S.LikeIconStyled
        icon={
          reactType === 'like'
            ? 'IcLike'
            : reactType === 'happy'
            ? 'IcHappy'
            : reactType === 'loved'
            ? 'IcIloved'
            : 'IcSuprise'
        }
      />
    </S.MotionDiv>
  );
};

export const AnimatedIcon = React.memo(AnimatedIconComponent, () => true);
