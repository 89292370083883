export const DefaultThemeLightTest = {
  logo:
    'https://storage-files-tm1-eventos.s3.amazonaws.com/marykay2024/logo-marykay.svg',
  button: {
    text: '#ffffff',
  },
  menuLabels: [
    'navbar.userMenu.profile',
    'navbar.plenary',
    'navbar.schedule',
    // 'navbar.coffee',
    // 'navbar.sponsors',
    // 'navbar.workshops',
    // 'navbar.pressMaterial',
    'navbar.material',
  ],
  accreditation: {
    kv: {
      pt:
        // 'https://storage-files-tm1-eventos.s3.amazonaws.com/bayer2023/auth/thumb-cadastro.mp4',
        'https://storage-files-tm1-eventos.s3.amazonaws.com/marykay2024/TM1_MK_Seminario2024_KV_668x768px+1.png',
      en: '',
      es: '',
    },
  },
  tertiary: '#FFDBE2',
  secondary: '#FFD1D9',
  inputChat: {
    backgroundColor: '#FFDBE2',
    textColor: '#000',
  },
  input: {
    outline: '#d7d7d7',
    text: '#000',
    label: '#7e7e7e',
    background: '#fff',
    selected: {
      outline: 'rgb(47, 229, 141)',
    },
  },
  countdown: {
    color: '#D51673',
    background: {
      card: {
        filterBlur: '',
        background: 'transparent',
      },
      image:
        'https://storage-files-tm1-eventos.s3.amazonaws.com/marykay2024/background-marykay.jpg',
    },
  },
  typography: {
    family: {
      primary:
        "Silka, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
    sizes: {
      xl: '32px',
      '2xl': '40px',
      '3xl': '56px',
      md: '18px',
      '4xl': '64px',
      sm: '14px',
      lg: '24px',
      xs: '12px',
      '2xs': '10px',
      base: '16px',
    },
    lineHeight: {
      sm: '16px',
      base: '24px',
      md: '32px',
    },
  },
  spacing: {
    xl: '48px',
    '2xl': '64px',
    '3xl': '88px',
    md: '24px',
    '4xl': '122px',
    sm: '16px',
    lg: '32px',
    xs: '8px',
    '2xs': '4px',
  },
  background: '#FFEAEE',
  footerBackground: '#FFDBE2',
  headerBackground: '#FFDBE2',
  SideBarBackground: '#FFD1D9',
  shapes: {
    borderRadius: {
      sm: '8px',
      lg: '24px',
      xs: '4px',
      full: '999999px',
      md: '16px',
    },
  },
  lineHeight: {
    sm: '16px',
    base: '24px',
    md: '32px',
  },
  text: {
    secondary: 'rgba(0, 0, 0, 0.50)',
    primary: '#000',
    details: '#fff',
  },
  colors: {
    skeleton: 'white',
  },
  primary: '#CF0F6B',
};
export const PreLoadedLightTheme = {
  primary: 'rgb(131, 131, 131)',
  secondary: '#ffffff',
  background: '#CF0F6B',
  colors: {
    skeleton: 'white',
  },
  input: {
    background: '#e5e5e5',
    outline: '#868686',
    text: 'white',
    selected: {
      outline: 'rgb(229, 47, 156)',
    },
  },
  text: {
    primary: '#000',
    secondary: '#7e7e7e',
  },
  button: {
    text: '#000',
  },
  spacing: {
    '2xs': '4px',
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '48px',
    '2xl': '64px',
    '3xl': '88px',
    '4xl': '122px',
  },
  typography: {
    family: {
      primary:
        "Silka, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
    sizes: {
      '2xs': '10px',
      xs: '12px',
      sm: '14px',
      base: '16px',
      md: '18px',
      lg: '24px',
      xl: '32px',
      '2xl': '40px',
      '3xl': '56px',
      '4xl': '64px',
    },
    lineHeight: {
      sm: '16px',
      base: '24px',
      md: '32px',
    },
  },
  lineHeight: {
    sm: '16px',
    base: '24px',
    md: '32px',
  },
  shapes: {
    borderRadius: {
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      full: '999999px',
    },
  },
};

export type NewThemeLight = typeof DefaultThemeLightTest & {
  default?: boolean;
};
