import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as Button from 'components/structure/Button/Button.styles';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const WrapperSpinner = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & + div {
      // margin-top: ${theme.spacing['2xl']};
    }

    > div {
      margin-top: 0;
    }
  `}
`;

export const ScheduleTopContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 680px;
    width: 100%;
    align-self: flex-end;

    ${Button.Wrapper} {
      ${media.lessThan('medium')`
        min-width: 110px;
        font-size: ${theme.typography.sizes.xs};
      `}
    }

    ${TypographyStyles.SubTitle} {
      margin-bottom: 0;

      ${media.lessThan('medium')`
        padding-right: ${theme.spacing.xs};
        line-height: ${theme.typography.lineHeight.md};
        font-size: ${theme.typography.sizes.lg};
      `}
    }
  `}
`;

export const ScheduleSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 860px;
`;

export const SaveOnAgenda = styled.text`
  font-size: 15px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  display: flex;
  color: black;
`;

export const ButtonOnAgenda = styled.button`
  border-radius: 3px;
  display: flex;
  border: 2px solid black;
  align-items: center;
  width: 130px;
  height: 40px;
  margin-left: 15px;
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
  text {
    font-size: 15px;
    align-items: center;
    text-align: center;
    font-weight: bold;
    display: flex;
    color: black;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  }
  &:hover {
    background-color: black;
    transform: scale(1.05);
    text {
      color: white;
    }
  }
`;

export const OnAgendaSession = styled.div`
  position: absolute;
  top: 43px;
  right: 70px;
`;
