/* eslint-disable @typescript-eslint/no-explicit-any */
//import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { useToast, useTranslate } from 'hooks';
import { setUserId } from 'lib/core/session';
import { Language, useIntlContext, useModalContext } from 'contexts';
//import { Unauthorized } from 'components/contexts/accreditation/Unathorized';
import { Logo } from 'components/structure';
import EventOptions from 'styles/overrides/EventOptions.theme';
import { User, UserExistsParams } from 'interfaces/user';
import { USERS } from 'constants/endpoints';
import { NOT_ALLOWED, NOT_FOUND } from 'constants/request';
import { ROUTES } from 'constants/urls';
import { useEvent } from './events';
import { useCreateUser, useUpdateCloseEventUser } from './users';

const { login } = ROUTES;

type UseHandleUserExistsProps = {
  incorrectDomain: (data: any) => void;
  onUserNotExists: (uuid: string, user?: User, referrer?: string) => void;
  onEventIsClosedAndUserNotExists: () => void;
};

export const useHandleUserExists = ({
  incorrectDomain,
  onUserNotExists,
  onEventIsClosedAndUserNotExists,
}: UseHandleUserExistsProps) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { setToast } = useToast();
  const [isPending, setIsPending] = useState(false);
  const { data } = useEvent();
  const isClosedEvent = data?.event?.closed;
  const userHasNoRegistration = undefined;
  //const [isAllowed, setIsAllowed] = useState(false);

  const handleUserExists = async (
    { uuid }: UserExistsParams,
    referrer?: string,
  ) => {
    setIsPending(true);
    const res = await api
      .get<{ completeRegistration: boolean }>(USERS.BY_UUID(uuid))
      .then(({ data }) => data)
      .catch(({ response }) => response);
    setIsPending(false);

    if (res?.status === 404) {
      return setToast({
        type: 'alert',
        description: translate('errors.unauthorizedEmail'),
      });
    }

    // // TODO: Caso o evento seja privado
    if (
      res?.status === 401 &&
      res?.data.error === 'email-domain-not-authorized'
    ) {
      return incorrectDomain(res.data.allowed);
    }

    if (EventOptions.accreditation?.isPrivate && res.status === NOT_ALLOWED) {
      return setToast({
        type: 'alert',
        description: translate('errors.unauthorizedEmail'),
      });
    }

    if (res?.completeRegistration === userHasNoRegistration && isClosedEvent) {
      return onEventIsClosedAndUserNotExists();
    }

    if (res?.status === NOT_FOUND && !isClosedEvent) {
      return onUserNotExists(uuid, res, referrer);
    }

    if (!res?.completeRegistration && isClosedEvent) {
      return onUserNotExists(uuid, res);
    }

    localStorage.setItem('isStaff', 'true');
    navigate(`/${login.base}?uuid=${uuid}`);
  };

  return { handleUserExists, isPending, setIsPending };
};

export const useCreateBusinessCard = () => {
  const createUser = useCreateUser();
  const [isPending, setIsPending] = useState(false);
  const { setModalProps, setShowModal } = useModalContext();
  const translate = useTranslate();
  const navigate = useNavigate();
  const { setLanguage } = useIntlContext();
  const { data } = useEvent();

  const createBusinessCard = async (
    user: User,
    hasInterests: boolean,
    hasConfirmationEmail?: boolean,
  ) => {
    setIsPending(true);
    const { user: userCreated } = await createUser({
      ...user,
      allowChat: true,
    });
    setIsPending(false);

    if (userCreated) {
      if (hasConfirmationEmail === false) {
        setUserId(userCreated.id!);
        setLanguage(userCreated.preferableLanguage as Language);
      }
      if (hasInterests) {
        navigate(ROUTES.accreditation.getLink('interests'));
      } else {
        if (data?.event.confirmationEmail) {
          setModalProps({
            title: translate(`accreditation.confirmationEmail.title`),
            icon: <Logo center light />,
            buttonLabel: translate('labels.close'),
            noBackground: true,
            description: translate(
              `accreditation.confirmationEmail.description`,
            ),
            confirmation: translate(
              `accreditation.confirmationEmail.warningDescription`,
            ),
            onClose: () => navigate('/accreditation'),
          });
          //
          return setShowModal(true);
        }
        setModalProps({
          icon: <Logo />,
          noBackground: true,
          title: translate('accreditation.almostThere.title'),
          confirmation: translate('accreditation.almostThere.confirmation'),
          // description: translate('accreditation.almostThere.description'),
          buttonLabel: translate('labels.close'),
          onClose: () => navigate(ROUTES.app.getLink('home')),
        });
        setShowModal(true);
      }
      if (hasConfirmationEmail === false) {
        setUserId(userCreated.id!);
      }
    }
  };
  return { createBusinessCard, isPending };
};

export const useIncorrectDomain = () => {
  const { setModalProps, setShowModal } = useModalContext();
  const translate = useTranslate();
  const navigate = useNavigate();

  const incorrectDomain = async (data: []) => {
    const domain = data.filter((item) => item !== 'tm1.com.br');
    setModalProps({
      icon: <Logo medium light />,
      noBackground: true,
      title: translate('accreditation.incorrectDomain.title'),
      description: translate('accreditation.incorrectDomain.description'),
      confirmation: `${translate(
        'accreditation.incorrectDomain.confirmation',
      )} : ${domain} `,
      buttonLabel: translate('accreditation.incorrectDomain.buttonLabel'),
      onClose: () => navigate(ROUTES.app.getLink('home')),
    });
    setShowModal(true);
  };

  return { incorrectDomain };
};

export const useUpdateBusinessCard = () => {
  const { updateUserCloseEvent } = useUpdateCloseEventUser();
  const [isPending, setIsPending] = useState(false);
  const { setModalProps, setShowModal } = useModalContext();
  const translate = useTranslate();
  const navigate = useNavigate();
  const { setLanguage } = useIntlContext();

  const updateBusinessCard = async (user: User, hasInterests: boolean) => {
    setIsPending(true);
    const { user: userCreated } = await updateUserCloseEvent({ ...user });
    setIsPending(false);

    if (userCreated) {
      setUserId(userCreated.id!);
      setLanguage(userCreated.preferableLanguage as Language);

      if (hasInterests) {
        navigate(ROUTES.accreditation.getLink('interests'));
      } else {
        setModalProps({
          icon: <Logo medium light />,
          noBackground: true,
          title: translate('accreditation.almostThere.title'),
          confirmation: translate('accreditation.almostThere.confirmation'),
          description: translate('accreditation.almostThere.description'),
          buttonLabel: translate('labels.close'),
          onClose: () => navigate(ROUTES.app.getLink('home')),
        });
        setShowModal(true);
      }
    }
  };

  return { updateBusinessCard, updateIsPeding: isPending };
};
