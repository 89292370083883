/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useSignIn } from 'useCases/auth';
import { useCreateUser } from 'useCases/users';
import { setUserId } from 'lib/core/session';
import { Language, useIntlContext } from 'contexts';
import { Spinner } from 'components/structure';
import { Logo } from 'components/structure';
import { User } from 'interfaces/user';
import { ROUTES } from 'constants/urls';
import * as S from './MaryKayAuth.styles';

/* eslint-disable prettier/prettier */
export function MKAuth() {
  const navigate = useNavigate();
  // const { language } = useIntlContext();
  const createUser = useCreateUser();
  const signIn = useSignIn();
  const [params] = useSearchParams();
  const { setLanguage } = useIntlContext();
  const mkCode = params.get('code');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  async function checkExist(email: string) {
    try {
      // const exists = await api.get(`/users/exists/${email}`);
      await api.get(`/users/exists/${email}`);
      return true;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
    }
  }
  function setDataForSuccessLoginOrSignup(userCreated: User) {
    setUserId(userCreated.id!);
    setLanguage(userCreated.preferableLanguage as Language);

    // responsavel por redirecionar o usuario para agenda
    const data = new Date();
    const dia = String(data.getDate()).padStart(2, '0');
    if (Number(dia) >= 7) {
      navigate(ROUTES.app.getLink('home'));
    } else {
      navigate('/reimagineVideo?selectedDate=07%2F11');
    }

    setUserId(userCreated.id!);
  }

  async function createIfDoesNotExist({
    email,
    firstName,
    lastName,
    password,
  }: User) {
    const newUser = await createUser({
      email,
      firstName,
      lastName,
      uuid: email?.toUpperCase(),
      password,
      rePassword: password,
      allowChat: true,
    } as any);
    if (newUser.user) {
      setDataForSuccessLoginOrSignup(newUser.user);
    }
  }
  async function loginIfExist(email: string, mk_user_id: string) {
    await signIn.handleAuthenticate({
      uuid: email,
      password: btoa(mk_user_id),
    } as any);
  }
  useEffect(() => {
    (async () => {
      try {
        setMessage('Validando consultora...');
        setIsLoading(true);
        const result = await api.post('/sso/mk-auth', {
          code: mkCode,
        });
        const data: {
          email: string;
          mk_user_id: string;
          firstName: string;
          lastName: string;
        } = result.data;
        setMessage('Validando cadastro na plataforma...');
        const exists = await checkExist(data.email);
        if (!exists) {
          setMessage('Cadastrando usuário...');
          await createIfDoesNotExist({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: btoa(data.mk_user_id),
          });
        } else {
          setMessage('Fazendo login...');
          await loginIfExist(data.email, data.mk_user_id);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response!.status === 401) {
            return navigate('/unauthorized');
          }
          if (err.response!.status === 400) {
            if (err.response!.data.error.error === 'invalid_grant') {
              navigate('/invalid-session');
            }
          }
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  // Houve um problema com sua sessão
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <S.Container>
      <S.Logo>
        <Logo />
      </S.Logo>
      <Spinner />
    </S.Container>
  );
}
