import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactions } from 'useCases/reactions';
import { useSession } from 'hooks';
import { AnimatedIcon } from 'components/structure';
import * as S from './FloatingHeart.styles';
import { ReactionsSpawnContainer } from './ReactionsSpawnContainer';

export type FloatProps = {
  position?: string;
};

const FloatingHeartComponent = ({ position }: FloatProps) => {
  const { pathname } = useLocation();
  const { id: userId } = useSession();
  const streamId = pathname.split('/')[2];
  const { handleSendReaction, reactions, setReactions } = useReactions({
    streamId: streamId,
  });

  return (
    <S.Container >
      <S.LikeIconStyled
        icon="IcLike"
        onClick={() => {
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'like',
          });
        }}
      />
      <S.LikeIconStyled
        icon="IcHappy"
        onClick={() =>
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'happy',
          })
        }
      />
      <S.LikeIconStyled
        icon="IcIloved"
        onClick={() =>
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'loved',
          })
        }
      />
      <S.LikeIconStyled
        icon="IcSuprise"
        onClick={() =>

          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'suprise',
          })
        }
      />
    <ReactionsSpawnContainer
    reactions={reactions}
    setReactions={setReactions}
    streamId={streamId}
    position={position}
    />
    </S.Container>
  );
};
export const FloatingHeart = memo(FloatingHeartComponent, () => true);