import { useEffect, useState } from 'react';
import { Interest, InterestProps } from 'components/contexts/users';
import * as S from './Interests.styles';

export type InterestsProps = {
  interests: InterestProps[];
  initalSelectedInterests?: string[];
  onChange?: (selectedInterests: string[]) => void;
};

export const Interests = ({
  interests = [],
  onChange,
  initalSelectedInterests = [],
}: InterestsProps) => {
  const [selectedInterests, setSelectedInterests] = useState<string[]>(
    initalSelectedInterests,
  );

  const handleClick = (value: string) => {
    const isSelected = selectedInterests.includes(value);

    setSelectedInterests((oldValues) =>
      !isSelected
        ? [...oldValues, value]
        : oldValues.filter((item) => item !== value),
    );
  };

  useEffect(() => {
    !!onChange && onChange(selectedInterests);
  }, [selectedInterests, onChange]);

  return (
    <S.Wrapper>
      {interests.map(({ value, id }) => (
        <Interest
          key={id}
          id={id}
          value={value}
          onClick={() => handleClick(id)}
          active={selectedInterests.includes(id)}
        />
      ))}
    </S.Wrapper>
  );
};

