import { useForm } from 'react-hook-form';
import { useHandleVoteSurvey } from 'useCases/surveys';
import { useTranslate } from 'hooks';
import { useSurveyContext } from 'contexts';
import { RadioButton, TextField } from 'components/form';
import { Button, Paragraph } from 'components/structure';
import * as S from './Questions.styles';

export const Questions = () => {
  const translate = useTranslate();
  const { isPending, handleVoteSurvey } = useHandleVoteSurvey();
  const { survey } = useSurveyContext();
  const { handleSubmit, register, watch } = useForm();
  const currentAnswer = watch('answer');

  const onSubmit = (test: { answer: string }) =>
    handleVoteSurvey(survey.id, [test.answer]);

  return (
    <S.Wrapper data-testid="questions-state">
      <Paragraph>{survey?.question?.text}</Paragraph>
      <form onSubmit={handleSubmit(onSubmit)}>
        {survey.question?.type === 'input' ? (
          <>
            <TextField
              key="1"
              id="1"
              name="answer"
              ref={register}
              placeholder={'Suporte'}
            />
          </>
        ) : (
          survey.alternatives.map(({ id, text }) => (
            <RadioButton
              key={id}
              register={register}
              id={id}
              name="answer"
              value={id}
              label={text}
              currentValue={currentAnswer}
            />
          ))
        )}
        <Button
          type="submit"
          isLoading={isPending}
          fullWidth
          disabled={isPending}
        >
          {translate('labels.vote')}
        </Button>
      </form>
    </S.Wrapper>
  );
};
