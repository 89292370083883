import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam, paramsDontMatch } = messages;

export const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required(() => requiredParam('newPassword'))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      'A senha deve ter no mínimo 8 dígitos: Letras maiúsculas, minúsculas, números e caracteres especiais.',
    ),
  rePassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], () => paramsDontMatch('newPassword'))
    .required(() => requiredParam('rePassword')),
  token: Yup.string(),
});

export const defaultValues = {
  newPassword: '',
  rePassword: '',
  token: '',
};

export type ChangePassword = Yup.InferType<typeof schema>;
