/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeContext } from 'app.contexts';
import { useContext } from 'react';
import { mutate } from 'swr';
import { useEnv, useFetch } from 'hooks';
import { getEventId } from 'lib/core/event';
import { Event } from 'interfaces/event';
import { EVENTS } from 'constants/endpoints';

export const useEvent = () => useEventById(useEnv().eventId);

export const useEventById = (id: string) => {
  const { setEventTheme, eventTheme } = useContext(ThemeContext);
  const event = useFetch<{ event: Event }>(EVENTS.BY_ID(id), undefined, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    
  });
  // console.log(eventTheme, event.data);
  if (eventTheme?.default && event.data?.event) {
    setEventTheme(event.data?.event.themes);
    localStorage.setItem('theme', JSON.stringify(event.data.event.themes));
  }
  return event;
};

export const revalidateOnChangeLanguage = (preferableLanguage: string) =>
  mutate(
    EVENTS.BY_ID(getEventId()!),
    (cachedEvent: Event) => ({
      ...cachedEvent,
      preferableLanguage,
    }),
    true,
  );
