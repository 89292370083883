import React from 'react';
import { ErrorMessage } from 'components/structure';

type ErrorMessagePageProps = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
};

export const ErrorMessagePage = ({
  title,
  subtitle,
  children,
}: ErrorMessagePageProps) => (
  <ErrorMessage title={title} subtitle={subtitle}>
    {children}
  </ErrorMessage>
);
