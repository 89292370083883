import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Heading } from 'components/structure';

export const Wrapper = styled.section`
  ${() => css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: ${'1300px'};
    margin: 0 auto;
    width: 100%;
    z-index: 1;
    cursor: pointer;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.lg};
    grid-template-columns: repeat(4, 1fr);

    ${media.lessThan('medium')`
      flex-flow: column;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    `}
  `}
`;

export const Title = styled(Heading)`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.lg};
    line-height: ${theme.typography.lineHeight.md};
  `}
`;
