/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNetworking, Connections } from 'useCases/networking';
import { useClickOutside, useTheme } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import { Avatar } from 'components/contexts/users';
import { Button, Icon } from 'components/structure';
import * as S from './MessageField.styles';

export type MessageFieldProps = {
	name: string;
	inputRef?: HTMLInputElement | null;
	isDisabled?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'step'> &
	Pick<UseFormMethods, 'register' | 'setValue' | 'getValues'>;

export type Params = {
	name?: string;
};

const MessageFieldComponent = ({
	name,
	register,
	setValue,
	getValues,
	isDisabled,
	inputRef,
	...props
}: MessageFieldProps) => {
	const theme = useTheme();
	const { formatMessage } = useIntl();
	const [showEmojis, setShowEmojis] = useState(false);
	const emojiSelectRef = useClickOutside(() => setShowEmojis(false));
	const [inputPosition, setInputPosition] = useState(0);

	const [params, setParams] = useState<Params>({
		name: '',
	});
	const { useOnlineUsers } = useNetworking();
	const { data: { connections } = {} } = useOnlineUsers(params);
	const [trigger, setTrigger] = useState(false);
	const [messageContent, setMessageContent] = useState('');
	const onEmojiClick = (_: React.MouseEvent, data: IEmojiData) => {
		setValue(name, getValues(name) + data?.emoji);
		setShowEmojis(false);
		(inputRef as any)?.current?.focus();
	};
	const [cachedConnections, setCachedConnections] = useState<any>(connections);
	/* eslint-disable @typescript-eslint/no-explicit-any */
	function updateMessageContent(event: any) {
		const content = event.target.value as string;
		setValue(name, content);
	}



	return (
		<S.Wrapper>
			<S.ButtonEmoji type="button" onClick={() => setShowEmojis(!showEmojis)}>
				<Icon icon="IcEmoji" />
			</S.ButtonEmoji>
			<S.Field
				type="text"
				onChange={updateMessageContent}
				// onKeyPress={updateMessageContent}
				// onKeyDown={updateMessageContent}
				ref={(event) => {
					register(event);
					if (inputRef) {
						(inputRef as any).current = event;
					}
				}}
				id={name}
				name={name}
				defaultValue={getValues()[name]}
				{...props}
			/>

			<S.ButtonWrapper>
				<Button
					onClick={() => {
						if ((inputRef as any)?.current) {
							(inputRef as any)?.current.blur();
							if ((inputRef as any)?.current.value === '@') {
								setTrigger(false);
							}
						}
					}}
					disabled={isDisabled}
					style={{
						backgroundColor: isDisabled === true ? 'gray' : theme.primary,
					}}
					type="submit"
					isRound
				>
					<Icon icon="IcSend" color={'white'} />
				</Button>
			</S.ButtonWrapper>
			<S.EmojiSelectWrapper ref={emojiSelectRef} show={showEmojis}>
				<Picker
					onEmojiClick={onEmojiClick}
					groupNames={{
						smileys_people: formatMessage({ id: 'interactions.emojis.people' }),
						animals_nature: formatMessage({
							id: 'interactions.emojis.animals',
						}),
						food_drink: formatMessage({ id: 'interactions.emojis.food' }),
						travel_places: formatMessage({ id: 'interactions.emojis.travel' }),
						activities: formatMessage({ id: 'interactions.emojis.active' }),
						objects: formatMessage({ id: 'interactions.emojis.objects' }),
						symbols: formatMessage({ id: 'interactions.emojis.symbols' }),
						flags: formatMessage({ id: 'interactions.emojis.flags' }),
						recently_used: formatMessage({
							id: 'interactions.emojis.recently',
						}),
					}}
				/>
			</S.EmojiSelectWrapper>

		</S.Wrapper>
	);
};
export const MessageField = memo(MessageFieldComponent, () => true);