import styled, { css } from 'styled-components';
import { AvatarProps } from './Avatar';

type WrapperProps = Pick<AvatarProps, 'size'>;

const wrapperModifiers = {
  xs: () => css`
    height: 24px;
    width: 24px;
  `,
  small: () => css`
    height: 32px;
    width: 32px;
  `,
  medium: () => css`
    height: 40px;
    width: 40px;
  `,
  large: () => css`
    height: 80px;
    width: 80px;
  `,
  full: () => css`
    height: 100%;
    width: 100%;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size = 'medium' }) => css`
    grid-row: 1/-1;
    border-radius: 50%;
    color: ${theme.text.primary};
    overflow: hidden;

    svg,
    img {
      height: 100%;
      width: 100%;
    }

    ${size && wrapperModifiers[size]()};
  `}
`;
