import axios, { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { mutate } from 'swr';
import { useAPI, useFetch, useTranslate } from 'hooks';
import { useModalContext } from 'contexts';
import { Logo, UploadProgress } from 'components/structure';
import { Photo, User, UserAPI } from 'interfaces/user';
import { ASSETS, USERS } from 'constants/endpoints';
import { LANGUAGE_KEY } from 'constants/enums';
import { revalidateOnChangeLanguage } from './events';

interface Params {
  name?: string;
  limit?: number;
  interests?: string[];
}

export const useUserById = (id: string) =>
  useFetch<{ user: User; accessToken: string }>(USERS.BY_ID(id));

export const useUserByUuid = (uuid: string) =>
  useFetch<{ user: User }>(USERS.BY_UUID(uuid));

export const useFetchOnlineUsers = (params: Params) => {
  const correctParams = params.name ? params : { ...params, limit: 10 };

  return useFetch<{ user: User[] }>(USERS.ONLINE, { params: correctParams });
};

export const useOnlineUsersByInterests = (interests: string[]) =>
  useFetch<{ user: User[] }>(USERS.ONLINE_BY_INTERESTS(interests));

export const useOnlineUsersByCountry = () =>
  useFetch(USERS.ONLINE_BY_COUNTRIES);

export const useCreateUser = () => {
  const api = useAPI();

  return async (user: User): Promise<UserAPI> => {
    const { data } = await api
      .post<UserAPI>(USERS.ROOT, user)
      .then((response) => response)
      .catch(({ response }) => response);
    if (data.user) {
      mutate(USERS.BY_ID(user.id!), data);
    }

    return data;
  };
};

export const useUpdateCloseEventUser = () => {
  const api = useAPI();

  const updateUserCloseEvent = useCallback(
    async (user: User) => {
      const { data } = await api
        .put<UserAPI>(USERS.BY_ID(user.id!), user)
        .then((response) => response)
        .catch(({ response }) => response);
      return data;
    },
    [api],
  );
  return { updateUserCloseEvent };
};

export const useUpdateUser = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);
  const [, setCookie] = useCookies(['language']);

  const updateUser = useCallback(
    async (user: User) => {
      let cachedUser = {};
      setIsPending(true);

      mutate(
        USERS.BY_ID(user.id!),
        (currentUser: User) => {
          cachedUser = currentUser;

          return { ...currentUser, ...user };
        },
        false,
      );
      if (user.country) {
        localStorage.setItem('country', user.country!.toString());
      }
      if (user.preferableLanguage) {
        setCookie(LANGUAGE_KEY, user.preferableLanguage, {
          path: '/',
        });
        revalidateOnChangeLanguage(user.preferableLanguage);
      }

      try {
        const { data } = await api.patch<UserAPI>(USERS.ROOT, {
          ...user,
          completeRegistration: true,
        });
        mutate(USERS.BY_ID(user.id!), data);
        return data;
      } catch (error) {
        mutate(USERS.BY_ID(user.id!), cachedUser);
        throw error;
      } finally {
        setIsPending(false);
      }
    },
    [api, setCookie],
  );

  return { isPending, updateUser };
};

export const useUploadPhoto = () => {
  const api = useAPI();
  const translate = useTranslate();
  const [isPending, setIsPending] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { setModalProps, setShowModal } = useModalContext();

  const onInit = () => {
    setModalProps({
      title: translate('users.waitAMoment'),
      icon: <Logo medium />,
      noBackground: true,
      hasButton: false,
      content: <UploadProgress value={uploadProgress} />,
    });
    setShowModal(true);
  };

  const uploadPhoto = async ({ file, fileContext, originalFile }: Photo) => {
    const options = {
      headers: { 'Content-Type': '' },
      onUploadProgress({ loaded, total }) {
        setUploadProgress(Math.round((loaded * 100) / total));
      },
    } as AxiosRequestConfig;

    try {
      onInit();
      const {
        data: { url },
      } = await api.get(ASSETS.PRE_SIGNED, {
        params: { fileName: file.name, fileContext },
      });

      await axios.put(url, file, options);

      if (originalFile) {
        await api.get(ASSETS.PRE_SIGNED, {
          params: { fileName: originalFile.name, fileContext: 'original' },
        });
      }
    } finally {
      setShowModal(false);
      setIsPending(false);
    }
  };

  return { isPending, uploadPhoto };
};
