import { useTranslate, useZendesk } from 'hooks';
import { Icon, IconsKeys } from 'components/structure';
import * as S from './ButtonSupport.styles';
export type ButtonSupportProps = {
  onlyIcon?: boolean;
  icon?: IconsKeys;
  fixedDesktop?: boolean;
};

export const ButtonSupport = ({
  onlyIcon,
  fixedDesktop,
  icon,
}: ButtonSupportProps) => {
  const translation = useTranslate();
  const handleSupport = useZendesk();
  // useEffect(() => {
  //   if (Event.data?.event?.zendesk?.open === true) {
  //     handleSupport();
  //   }
  // });
  return (
    <S.ButtonSupport
      onlyIcon={onlyIcon}
      fixedDesktop={fixedDesktop}
      onClick={handleSupport}
      fullWidth
      isRound
      color="primary"
      type="button"
    >
      <Icon icon={icon ?? 'IcSupport'} />
      {!onlyIcon && translation('footer.support')}
    </S.ButtonSupport>
  );
};
