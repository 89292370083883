import { AppContexts } from 'app.contexts';
import React from 'react';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';
import Routes from 'routes';
import { SettingsProvider } from 'contexts';

import 'assets/fonts/Silka/index.css';
//import 'assets/fonts/BebasNeue/index.css';
import 'assets/fonts/Caec-LT/index.css';
import 'assets/fonts/Myriad/index.css';
import 'assets/fonts/Etelka-Text-Pro/index.css';

const swrConfigs = { revalidateOnFocus: false, shouldRetryOnError: false };

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig value={swrConfigs}>
      <SettingsProvider>
        <AppContexts>
          <Routes />
        </AppContexts>
      </SettingsProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root'),
);
