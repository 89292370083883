import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Icon } from 'components/structure/Icon/Icon';

interface LikeIconStyled {
  color: string;
}

interface MotionStyled {
  position?: string;
}

export const LikeIconStyled = styled(Icon)<any>`
  width: 43.62px;
  height: 39.1px;
  @media (max-width: 414px) {
    width: 35px;
    height: 32px;
  }
`;

export const MotionDiv = styled(motion.div)<MotionStyled>`
  pointer-events: none;
  position: absolute;
  margin-right: ${({ position }) =>
    position === 'left'
      ? '-200px'
      : position === 'right'
      ? '-180px'
      : position === 'center-left'
      ? 'calc(100vw - 80vw )'
      : position === 'center'
      ? '-180px'
      : 'calc(60vw - 100vw )'};
  bottom: 0;

  @media (max-width: 500px) {
    margin-right: ${({ position }) =>
      position === 'left'
        ? '-150px'
        : position === 'right'
        ? '-230px'
        : position === 'center-left'
        ? '-20px'
        : position === 'center'
        ? '-150px'
        : '-310px'};
  }
  @media (max-width: 730px) {
    margin-right: ${({ position }) =>
      position === 'left'
        ? '-150px'
        : position === 'right'
        ? '-230px'
        : position === 'center-left'
        ? '-20px'
        : position === 'center'
        ? '-180px'
        : '-310px'};
  }
`;
