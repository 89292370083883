/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useEvent } from 'useCases/events';
import { createContext, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { isSafari } from 'react-device-detect';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { ToastProvider, IntlProvider, useSettingsContext } from 'contexts';
import { CurrentChatIdProvider } from 'contexts/CurrentChatIdContext'
import GlobalStyles from 'styles/global';
import {
	DefaultThemeTest,
	NewTheme,
	PreLoadedTheme,
} from 'styles/themes/default';
import {
	DefaultThemeLightTest,
	// NewThemeLight,
	// PreLoadedLightTheme,
} from 'styles/themes/defaultLight';
import { WithChildren } from 'interfaces/children';

// eslint-disable-next-line @typescript-eslint/ban-types
export const ThemeContext = createContext<{
	eventTheme?: NewTheme & { updated?: boolean };
	themeChosen?: any;
	setThemeChosen: (val: any) => void;
	setEventTheme: (val: any) => void;
}>({
	setThemeChosen: () => { },
	themeChosen: '',
	setEventTheme: () => { },
	eventTheme: undefined,
});

export const AppContexts = ({ children }: WithChildren) => {
	const stage = 'dev' as 'prd' | 'dev';
	const { isBlockScroll, hasStream } = useSettingsContext();

	const [themeChosen, setThemeChosen] = useState('light');
	const hasStoredTheme = localStorage.getItem('theme');
	const currentFallbackTheme = hasStoredTheme
		? JSON.parse(localStorage.getItem('theme')!)
		: PreLoadedTheme;

	const [eventTheme, setEventTheme] = useState<
		(NewTheme & { updated?: boolean; default?: boolean }) | any
	>(
		stage === 'dev'
			? DefaultThemeTest
			: { ...currentFallbackTheme, default: true },
	);


	useEffect(() => {
		if (stage === 'dev' && themeChosen === 'light') {
			setEventTheme(DefaultThemeLightTest);
		}
		if (stage === 'dev' && themeChosen === 'dark') {
			setEventTheme(DefaultThemeTest);
		}
	}, [themeChosen]);
	return (
		<ThemeContext.Provider
			value={{ setThemeChosen, setEventTheme, eventTheme, themeChosen }}
		>
			<CookiesProvider>
			<ThemeProvider theme={eventTheme}>
				<CurrentChatIdProvider>
					<ToastProvider>
						<IntlProvider>
							<GlobalStyles
								blockScroll={isBlockScroll}
								hasStream={hasStream}
								isSafari={isSafari}
							/>
							<HelmetProvider>{children}</HelmetProvider>
						</IntlProvider>
					</ToastProvider>
				</CurrentChatIdProvider>
			</ThemeProvider>
			</CookiesProvider>
		</ThemeContext.Provider>
	);
};
