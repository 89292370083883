/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import { ThemeContext } from 'app.contexts';
import { useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useCertificate } from 'useCases/assets';
import { useEventById } from 'useCases/events';
import { useUpdateUser, useUserById } from 'useCases/users';
import { useEnv, useSession } from 'hooks';
import { getOptions } from 'lib/contexts/navbar';
import {
  LanguageStream,
  useIntlContext,
  useSettingsContext,
  ThemeCustomProvider,
} from 'contexts';
import { Interactions } from 'components/contexts/interactions';
import { NotificationsWatcher } from 'components/contexts/listeners';
import {
  Footer,
  MediaMatch,
  Navbar,
  ServerErrorGuard,
} from 'components/structure';
import { floatingMenuItemsMock } from 'components/structure/Navbar/Navbar.mock';
import optionsMock from 'components/structure/Navbar/Navbar.mock';
import * as PrivateTheme from 'styles/overrides/Private.theme';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { Certificate, GA_EVENTS } from 'constants/enums';
import { PrivateContexts } from './Private.contexts';
import * as S from './Private.styles';

const Private = () => {
  const { id: userId } = useSession();
  const { languageStream, setLanguageStream } = useIntlContext();
  const {
    hasFooter,
    hasInteraction,
    isSpaceFull,
    hasTranslationChange,
    setIsBlockScroll,
    hasError,
    hasNotification,
  } = useSettingsContext();

  const { eventId } = useEnv();
  const { updateUser } = useUpdateUser();
  const userById = useUserById(userId!);
  const eventById = useEventById(eventId);
  const handleDownloadCertificate = useCertificate();
  const eventCertificate = eventById?.data?.event.certificate;
  const hasCertificate = eventCertificate !== Certificate.NOTEXISTS;
  const hasMemberGetMember = eventById?.data?.event.hasMemberGetMember;
  const eventName = eventById?.data?.event.name;
  const { eventTheme } = useContext(ThemeContext);

  const certificateOption = useMemo(
    () => ({
      label: 'navbar.userMenu.generateCertificate',
      onClick: () => handleDownloadCertificate(eventName),
      url: '',
      internal: false,
      disabled: eventCertificate === Certificate.UNAVAILABLE,
      ga: {
        action: EventAction.click,
        label: GA_EVENTS.navBar.labels.certificate,
        category: EventCategory.Navigation,
      },
    }),
    [eventCertificate, eventName, handleDownloadCertificate],
  );
  const filteredMenuItems = useMemo(() => {
    const mergedOptions = hasCertificate
      ? [certificateOption, ...floatingMenuItemsMock]
      : floatingMenuItemsMock;

    return mergedOptions.filter(({ label }) =>
      hasMemberGetMember ? label : label !== 'navbar.userMenu.rewards',
    );
  }, [certificateOption, hasCertificate, hasMemberGetMember]);
  useEffect(() => {
    if (userById.data?.user && !userById.data?.user?.accessedPlatform) {
      updateUser({ accessedPlatform: true });
    }
  }, [userById.data?.user, updateUser]);


  //verificar
  return (
    <ThemeCustomProvider {...PrivateTheme}>
      <PrivateContexts>
        <S.Wrapper
          hasInteractions={hasInteraction}
          hasFooter={hasFooter}
          removeSpace={isSpaceFull}
          // backgroundImage={THEME.BackgroundOutlet ?? ''}
        >
          <Navbar
            logoUrl={eventTheme!.logo}
            setIsBlockScroll={setIsBlockScroll}
            options={getOptions(optionsMock, eventTheme!.menuLabels)}
            logoLabel={eventById.data?.event.name}
            hasInteractions={hasInteraction}
            language={languageStream}
            onChangeLanguage={(lang: LanguageStream) => setLanguageStream(lang)}
            isTranslationChange={hasTranslationChange}
            hasNotification={hasNotification}
            hasMemberGetMember={hasMemberGetMember}
            menuItems={filteredMenuItems}
          />

          <S.OutletWrapper hasInteractions={hasInteraction}>
            <ServerErrorGuard isError={hasError}>
              <Outlet />
            </ServerErrorGuard>
          </S.OutletWrapper>

          <Interactions show={hasInteraction} />

          <MediaMatch greaterThan="large">
            <Footer
              avatar={userById.data?.user.avatar}
              userName={userById.data?.user.firstName}
              show={hasFooter}
              onChangeLanguage={(lang: LanguageStream) =>
                setLanguageStream(lang)
              }
              isTranslationChange={hasTranslationChange}
              language={languageStream}
              hasInteractions={hasInteraction}
              hasNotification={hasNotification}
              hasMemberGetMember={hasMemberGetMember}
              menuItems={filteredMenuItems}
            />
          </MediaMatch>
          <NotificationsWatcher />
        </S.Wrapper>
      </PrivateContexts>
    </ThemeCustomProvider>
  );
};

export {Private};
