import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { dispatchGAEvent } from 'services/google';
import { useTranslate } from 'hooks';
import { hoursIntervalWithoutSeconds } from 'lib/contexts/date';
import { useModalContext, useIntlContext } from 'contexts';
import { Icon } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { StreamState } from 'interfaces/stream';
import { LanguagesAcronyms } from 'constants/enums';
import { GA_EVENTS } from 'constants/enums';
import { ROUTES } from 'constants/urls';
import * as S from './PlenaryCard.styles';

export type PlenaryCardProps = {
  title: string;
  streamState: StreamState;
  description: string;
  thumbnail: string;
  scheduledStartTime: string;
  scheduledEndTime: string;
  id: string;
  small?: boolean;
};

export const PlenaryCard = ({
  id,
  title,
  streamState,
  description,
  thumbnail,
  scheduledStartTime,
  scheduledEndTime,
  small = false,
}: PlenaryCardProps) => {
  const { language } = useIntlContext();
  const translation = useTranslate();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const { setModalProps, setShowModal } = useModalContext();
  const live = streamState === StreamState.Live;
  // const isPlenaryTime = true;

  // const goToSchedule = () => {
  //   navigate('/schedule');
  // };

  useEffect(() => {
    setModalProps({
      icon: <Icon icon="IcAlarm" />,
      title: translation('stream.notLive'),
      description: translation('stream.notLiveDescription'),
      buttonLabel: translation('labels.understood'),
      onClose: () => setShowDialog(!showDialog),
    });
    setShowModal(showDialog);
  }, [setModalProps, setShowModal, showDialog, translation]);

  const time = () =>
    hoursIntervalWithoutSeconds(
      scheduledStartTime,
      scheduledEndTime,
      LanguagesAcronyms[language],
      // isPlenaryTime,
    );

  const handlerStreamOrShowDialog = () => {
    dispatchGAEvent({
      category: EventCategory.Stream,
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.selectedStream(time()),
    });

    if (live) navigate(ROUTES.stream.getLink('id', id));
    else if (streamState === StreamState.Finished) return;
    else setShowDialog(!showDialog);
  };

  const streamLabel = (streamState: StreamState) => {
    switch (streamState) {
      case StreamState.Live: {
        return <S.SoonLabel redFlag>{translation('labels.live')}</S.SoonLabel>;
      }
      case StreamState.Finished: {
        return;
      }
      case StreamState.WatchAgain: {
        return <S.SoonLabel>{translation('stream.watchAgain')}</S.SoonLabel>;
      }
      default:
        return <S.SoonLabel>{translation('labels.soon')}</S.SoonLabel>;
    }
  };

  return (
    <>
      <S.Wrapper small={small}>
        <S.Anchor>
          <S.PictureWrapper onClick={handlerStreamOrShowDialog}>
            <img src={thumbnail} alt={title} />
            {streamLabel(streamState)}
          </S.PictureWrapper>
          <S.ContainerTitleSchedule>
            <S.Title role="heading">{title}</S.Title>
            {/* <S.ButtonSchedule onClick={goToSchedule}>
              <Icon icon="IcSchedule" width="24px" height="24px" />
              Ver agenda completa
            </S.ButtonSchedule> */}
          </S.ContainerTitleSchedule>
          {/* <S.Time hasDot role="time-string">
            {time()}
          </S.Time> */}
          <S.Excerpt
            role="paragraph"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </S.Anchor>
      </S.Wrapper>
    </>
  );
};
