import { useEffect } from 'react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useEvent } from 'useCases/events';
import { useEnv, useQueryString } from 'hooks';
import { ErrorBoundary } from 'components/structure';
import { EventStatus } from 'interfaces/event';
import { ROUTES } from 'constants/urls';
import { Skeleton } from './Shield.skeleton';

type ShieldProps = {
  openOnlyAfterCountdownEnd?: boolean;
};

type Params = {
  bypass?: string;
};

export const Shield = ({
  openOnlyAfterCountdownEnd = true,
  children,
}: PropsWithChildren<ShieldProps>) => {
  const { data, isLoading } = useEvent();
  const navigate = useNavigate();
  const { isDev } = useEnv();
  const { pathname } = useLocation();
  const params = useQueryString() as Params;
  const hasMemberGetMember = data?.event.hasMemberGetMember;
  const eventStatus = data?.event.status;
  // const session = useRealTimeSession();

  useEffect(() => {
    if (
      openOnlyAfterCountdownEnd &&
      eventStatus === EventStatus.ShowCountdown &&
      !isLoading &&
      !isDev &&
      !params?.bypass &&
      !localStorage.getItem("skipCountdown")
    ) {
      if (hasMemberGetMember) {
        navigate(ROUTES.countdown.getLink('userCountdown'));
      } else {
        if (
          pathname.split('/')[1] === 'configurations' ||
          pathname.split('/')[1] === 'help-center'
        ) {
          return;
        }
        navigate(ROUTES.countdown.getLink('base'));
      }
    }
    if (eventStatus === EventStatus.ShowStreams || eventStatus === EventStatus.ShowWatchAgain) {
      if (window.location.pathname === '/') {
        navigate('/home')
      }
    }
  }, [
    isLoading,
    openOnlyAfterCountdownEnd,
    navigate,
    eventStatus,
    isDev,
    params,
    hasMemberGetMember,
    pathname,
  ]);
  if (params?.bypass) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }
  if (isDev) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};
