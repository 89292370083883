import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';

type WrapperProps = {
  hasInteractions?: boolean;
  removeSpace?: boolean;
  hasFooter?: boolean;
  backgroundImage?: string;
};

type OutletWrapperProps = {
  hasInteractions?: boolean;
};

const containerPrivateModifiers = {
  withInteractions: () => css`
    width: calc(100% - ${'360px'});

    ${media.lessThan('large')`
      width: 100%;
    `}
  `,
  removeSpace: () => css`
    gap: 0;
  `,

  removeSpaceFooter: () => css`
    grid-template-rows: ${'80px'} 1fr;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    hasInteractions = true,
    removeSpace = false,
    hasFooter = true,
    backgroundImage = '',
  }) => css`
    background: ${theme.background} url(${backgroundImage}) no-repeat center;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    transition: width 0.2s ease-in-out;
    display: grid;
    grid-template-areas: 'head' 'main' 'footer';
    grid-template-rows: ${'80px'} 1fr ${'80px'};
    grid-template-columns: 100%;
    gap: ${theme.spacing.md} 0;
    padding: 0;
    width: 100%;

    ${media.lessThan('large')`
      grid-template-areas: 'head' 'main' 'footer';
      grid-template-rows: ${'56px'} 1fr;
      grid-template-columns: 1fr;
    `}

    /* ${media.between('medium', 'large')`
      padding-bottom: 5%;
    `} */

    ${hasInteractions && containerPrivateModifiers.withInteractions()};
    ${removeSpace && containerPrivateModifiers.removeSpace()};
    ${removeSpace &&
    !hasFooter &&
    containerPrivateModifiers.removeSpaceFooter()};
  `}
`;

const outletWrapperModifiers = {
  withInteractions: () => css`
    ${media.lessThan('medium')`
      padding-bottom: 80px;
    `}
  `,
};

export const OutletWrapper = styled.main<OutletWrapperProps>`
  ${({ hasInteractions }) => css`
    grid-area: main;

    ${hasInteractions && outletWrapperModifiers.withInteractions()}
  `}
`;
