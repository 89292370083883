/* eslint-disable @typescript-eslint/no-explicit-any */
import 'videojs-contrib-hls';
// import 'video.js/dist/video-js.css';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import videojs, { VideoJsPlayer } from 'video.js';
import { UnmuteLabel } from 'components/contexts/interactions/VirtualRoom/VirtualRoom.styles';
import * as S from './styles';

export const VideoJS = (props: {
  options: any;
  onReady?: any;
  ready?: boolean;
  src?: string;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<VideoJsPlayer>(null);
  const [showUnmuteMessage, setShowUnmuteMessage] = useState(false);
  const { options, onReady } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (!playerRef.current) {
      const checkReferrer = () => {
        const referrer = localStorage.getItem('refresh')
          ? 'external'
          : 'react-router-dom';
        referrer === 'external' && localStorage.removeItem('refresh');
        referrer === 'external' && setShowUnmuteMessage(true);
        return referrer;
      };

      const path = (window.location.pathname
        .split('/')
        .filter((i) => i !== '') as any).at(-1);

      const referrer = checkReferrer();
      const muted = referrer === 'external';
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef?.current?.appendChild(videoElement);
      const player = ((playerRef as any).current = videojs(
        videoElement,
        {
          'videojs-contrib-hls': {
            withCredentials: false,
            overrideNative: true,
          },
          muted,
          ...options,
        },
        () => {
          onReady && onReady(player);
          player.play();
        },
      ));
      player.play();
      (player as any).on('enterpictureinpicture', () => {
        localStorage.setItem('pip-ref', path!);
      });
      (player as any).on('leavepictureinpicture', () => {
        if (
          !window.location.pathname.includes(localStorage.getItem('pip-ref')!)
        ) {
          player.dispose();
          (videoRef as any).current = null;
          navigate('/stream/' + localStorage.getItem('pip-ref')! || '/');
        }
      });
      (player as any).on('volumechange', () => {
        setShowUnmuteMessage(false);
      });

      player.playsinline(true);
    } else {
      const player = playerRef.current;
      player.autoplay(true);
      player.src(options.sources);
    }
    return () => {
      if (playerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars
        const player = playerRef.current;
        // player.dispose();
        (videoRef as any).current = null;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, onReady, /* options ,*/ videoRef]);
  React.useEffect(() => {
    window.addEventListener('beforeunload', registerAction);
    return () => {
      window.removeEventListener('beforeunload', registerAction);
    };
  }, []);
  const registerAction = () => {
    localStorage.setItem('refresh', 'true');
  };

  return (
    <S.VideoContainer
      data-vjs-player
      style={{ position: 'relative' }}
      onClick={() => {
        if (showUnmuteMessage) {
          playerRef.current!.muted(false);
          playerRef.current!.play();
        }
      }}
    >
      <div ref={videoRef as any} style={{ position: 'relative' }}>
        {showUnmuteMessage && (
          <UnmuteLabel redFlag>Clique para ativar o áudio 🔈</UnmuteLabel>
        )}
      </div>
    </S.VideoContainer>
  );
};

export default memo(VideoJS);
// export default VideoJS;
