import { useEffect, useState } from 'react';
import { useSession } from 'hooks/useSession';
import { UserChat } from 'components/contexts/interactions';
import { Message } from 'interfaces/chat';

interface WithId {
  id: string;
  chatId?: string;
}

export const removeDuplicatedMessages = <Type extends WithId>(
  prevItems: Type[] = [],
  newItems: Type[] = [],
  key: 'id' | 'chatId' = 'id',
) => {
  const itemsMerged = [...newItems, ...prevItems];
  const arrayOfIds = itemsMerged.map((item) => item[key]);
  const uniqueIds = Array.from(new Set(arrayOfIds));

  const uniqueItems = uniqueIds.map((id) =>
    itemsMerged.find((item) => item[key] === id),
  );

  return uniqueItems as Type[];
};

const STAFF = 'staff';

export const useTransformMessages = (messagesFromAPI: Message[] = [], limit?: number) => {
  const { id: userSessionId } = useSession();
  const [messages, setMessages] = useState<UserChat[]>([]);

  useEffect(() => {
    if (messagesFromAPI.length) {
      const messagesTransformed = messagesFromAPI.map(
        ({
          content,
          id,
          userId,
          name,
          avatar,
          country,
          state,
          company,
          email,
          isSended,
          role,
          createdAt,
          likes,
          mentions,
        }) => ({
          id,
          avatar,
          userId,
          name,
          country,
          state,
          company,
          email,
          message: content,
          isSended: isSended ?? true,
          received: userId !== userSessionId,
          isStaff: role?.includes(STAFF),
          createdAt,
          likes,
          mentions,
        }),
      );
      setMessages([...messagesTransformed.slice(0, limit || Infinity)]);
    }
  }, [messagesFromAPI, userSessionId]);

  return messages;
};
