import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import * as UserCardStyles from 'components/contexts/users/UserCard/UserCard.styles';
import { DefaultScroll } from 'components/structure';
import * as Form from 'components/structure/Form/Form';
import * as TypographStyles from 'components/structure/Typography/Typography';

type WrapperProps = {
  hasUserSelected?: boolean;
};

const wrapperModifiers = {
  hasUserSelected: (theme: DefaultTheme) => css`
    ${media.lessThan('medium')`
      padding: 0;

      ${List} {
        padding: ${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing['2xl']};
      }

      ${AvailableUsers} {
        margin-top: 0;
      }

      ${Form.FormGroup} {
        display: none;
      }
    `}
  `,
};

export const Wrapper = styled.article<WrapperProps>`
  ${({ theme, hasUserSelected }) => css`
    background-color: ${theme.SideBarBackground};
    color: ${theme.text.secondary};
    padding: ${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing['2xl']};
    position: relative;
    width: 100%;
    height: 100%;

    ${TypographStyles.SubTitle} {
      margin-bottom: ${theme.spacing.xs};
    }

    ${Form.FormGroup} {
      grid-gap: ${theme.spacing.sm};
    }

    ${DefaultScroll}
    ${hasUserSelected && wrapperModifiers.hasUserSelected(theme)};
  `}
`;

const availableUsersModifiers = {
  noGrid: () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    padding-bottom: 0;
  `,
};

export const AvailableUsers = styled.div<{ noGrid: boolean }>`
  ${({ theme, noGrid }) => css`
    margin-top: ${theme.spacing.md};
    padding-bottom: ${theme.spacing['2xl']};
    width: 100%;
    overflow-y: overlay;
    height: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    ${media.greaterThan('medium')`
      max-height: calc(100% - 80px);
    `}

    ${DefaultScroll}

    ${noGrid && availableUsersModifiers.noGrid()};
  `}
`;

export const List = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.spacing.xs} ${theme.spacing.sm};
    padding-bottom: ${theme.spacing.sm};
  `}
`;

const userItemModifiers = {
  isSelected: () => css`
    ${UserCardStyles.Wrapper} {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -44%);
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;

      ${media.lessThan('medium')`
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: unset;
        z-index: ${'50'};
      `}
    }

    ${UserCardStyles.Container} {
      transition: transform 0.3s ease-in-out;
    }
  `,
};

export const UserWrap = styled.div<{ isSelected: boolean }>`
  ${({ isSelected }) => css`
    ${isSelected && userItemModifiers.isSelected()}
  `}
`;

export const UserItem = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${theme.shapes.borderRadius.md};
    padding: ${theme.spacing.sm} 0;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    min-width: 90px;
    width: 90px;

    * {
      cursor: pointer;
    }

    > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    > span {
      margin-top: ${theme.spacing.xs};
      color: ${theme.text.primary};
    }

    &:hover {
      background: ${theme.secondary};
      border-color: ${theme.primary};
    }
  `}
`;

export const SpinnerWrap = styled.div`
  width: 100%;
  height: 80px;
`;
