import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
  // useNavigate,
} from 'react-router-dom';
import { useEnv } from 'hooks';
import { isAuthenticated } from 'lib/core/session';
import * as Accreditation from 'pages/accreditation';
import {
  ChangePassword,
  ForgotPassword,
  SignIn,
  ConfirmRegistration,
} from 'pages/authentication';
import { Coffee } from 'pages/coffee';
import * as Configurations from 'pages/configurations';
import { NotFound, ErrorMessagePage } from 'pages/error';
import { HelpCenter } from 'pages/help-center';
import { Home } from 'pages/home';
import { FacebookAuth } from 'pages/integrations';
import { MKAuth } from 'pages/integrations/MaryKayAuth';
import { Materials } from 'pages/materials';
//import { MaterialsCreators } from 'pages/materialsCreators';
import { ReimagineMaterial } from 'pages/reimagineVideo/Materials';
import { Rewards } from 'pages/rewards/Rewards';
import { ScheduleDetails, Schedule } from 'pages/schedule';
import { Sponsors } from 'pages/sponsors/Sponsors';
import { Stream } from 'pages/streams';
import VirtualExpo from 'pages/virtual-expo/VirtualExpo';
import { Private, Public, Shield } from 'components/contexts/routes';
import { Button, Helment } from 'components/structure';
import { ROUTES } from 'constants/urls';

const {
  accreditation,
  configurations,
  countdown,
  app,
  stream,
  login,
  schedule,
  schedulePublic,
  integrations,
} = ROUTES;

export interface RouteProps {
  children?: React.ReactNode;
  element?: React.ReactElement | null;
  path?: string;
}

// TODO bloquear rotas privadas de acordo com a data do evento
export const PrivateRoute = ({
  path,
  element,
  children,
  ...props
}: RouteProps) => {
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const { mkSSOUrl } = useEnv();
  // const mkSSOUrl = "https://amr1uat-mkamr-marykayintouch.cs43.force.com/br/services/oauth2/authorize?client_id=3MVG9GnaLrwG9TQQPR9BSKWd2kvCapYQ_JPDHg1mDLGIltrKBg16qKSai4liBA1gtOCltXMKpJqat_SD3sLZr&redirect_uri=https%3A%2F%2Fhml.marykay.tm1.com.br%2F&response_type=code&scope=openid&code_challenge=m6NDIHoYWmVs4ZmfO1wi0zsLjTY4IDoR4O5V77hBJog"
  if (!isAuthenticated() && pathname == '/') {
    // navigate('/teste')
    const mkCode = params.get('code');
    if (mkCode) {
      return <Navigate to={`/integrations/mk-sso?code=${mkCode}`} />;
    }
    window.location.href = mkSSOUrl;
    return <></>;
  }
  return (
    <Route path={path} element={element} {...props}>
      {children}
    </Route>
  );
};

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="unauthorized"
        element={
          <ErrorMessagePage
            title="VIVA MARY KAY SEMINÁRIO 2024"
            subtitle="Sua inscrição não foi encontrada. Caso tenha se inscrito e não está conseguindo acessar, entre em contato com a nossa central de atendimento."
          />
        }
      />
      <Route
        path="invalid-session"
        element={
          <>
            <ErrorMessagePage
              title="VIVA MARY KAY SEMINÁRIO 2024"
              subtitle="Ocorreu um problema na sessão. Clique no botão abaixo para tentar novamente."
            >
              <Button
                color="tertiary"
                style={{ marginTop: '1rem' }}
                onClick={() => {
                  window.location.href = process.env.REACT_APP_MK_SSO_URL!;
                }}
              >
                Tentar Novamente
              </Button>
            </ErrorMessagePage>
          </>
        }
      />
      <PrivateRoute
        path={app.base}
        element={
          <Shield>
            <Private />
          </Shield>
        }
      >
        {/* <PrivateRoute
          path={app.base}
          element={<Navigate to={app.children.home} />}
        /> */}
        <PrivateRoute
          path={app.children.home}
          element={
            <Shield>
              <Home />
            </Shield>
          }
        />
        <PrivateRoute
          path={app.children.expo}
          element={
            <Shield>
              <VirtualExpo />
            </Shield>
          }
        />
        <PrivateRoute
          path={app.children.helpCenter}
          element={
            <Shield>
              <HelpCenter />
            </Shield>
          }
        />
        <PrivateRoute
          path={app.children.material}
          element={
            <Shield>
              <Materials />
            </Shield>
          }
        />
        <PrivateRoute
          path="reimaginevideo"
          element={
            <Shield>
              <ReimagineMaterial />
            </Shield>
          }
        />
        <PrivateRoute
          path={app.children.coffee}
          element={
            <Shield>
              <Coffee />
            </Shield>
          }
        />
        <PrivateRoute
          path={app.children.rewards}
          element={
            <Shield>
              <Rewards />
            </Shield>
          }
        />

        <PrivateRoute
          path={app.children.sponsors}
          element={
            <Shield>
              <Sponsors />
            </Shield>
          }
        />

        <PrivateRoute
          path={configurations.base}
          element={
            <Shield>
              <Configurations.Configurations />
            </Shield>
          }
        >
          <PrivateRoute
            path={configurations.children.root}
            element={<Navigate to={configurations.children.photo} />}
          />
          <PrivateRoute
            path={configurations.children.personalData}
            element={
              <Shield>
                <Configurations.ConfigurationsPersonalData />
              </Shield>
            }
          />
          <PrivateRoute
            path={configurations.children.photo}
            element={
              <Shield>
                <Configurations.ConfigurationsChoosePhoto />
              </Shield>
            }
          />
          <PrivateRoute
            path={configurations.children.interests}
            element={
              <Shield>
                <Configurations.ConfigurationsInterests />
              </Shield>
            }
          />
        </PrivateRoute>

        <PrivateRoute path={schedule.base} element={<Outlet />}>
          <PrivateRoute
            path={schedule.children.base}
            element={
              <Shield>
                <Schedule />
              </Shield>
            }
          />
          <PrivateRoute
            path={schedule.children.id}
            element={
              <Shield>
                <ScheduleDetails />
              </Shield>
            }
          />
        </PrivateRoute>

        <PrivateRoute path={stream.base} element={<Outlet />}>
          <PrivateRoute
            path={stream.children.id}
            element={
              <Shield>
                <Stream />
              </Shield>
            }
          />
        </PrivateRoute>
      </PrivateRoute>

      <Route path={schedulePublic.base} element={<Outlet />}>
        <Route path={schedule.children.base} element={<Schedule />} />
        <Route path={schedule.children.id} element={<ScheduleDetails />} />
      </Route>

      <Route path={login.base} element={<Public />}>
        <Route path={login.children.signIn} element={<SignIn />} />
        <Route
          path={login.children.forgotPassword}
          element={<ForgotPassword />}
        />
        <Route
          path={login.children.resetPassword}
          element={<ChangePassword />}
        />
        <Route
          path={login.children.userConfirmation}
          element={<ConfirmRegistration />}
        />
      </Route>

      <Route path={accreditation.base} element={<Public />}>
        <Route
          path={accreditation.children.gettingStarted}
          element={<Accreditation.GettingStarted />}
        />
        <Route
          path={accreditation.children.createBusinessCard}
          element={<Accreditation.CreateBusinessCard />}
        />
        <Route
          path={accreditation.children.personalData}
          element={<Accreditation.PersonalData />}
        />
        <PrivateRoute
          path={accreditation.children.interests}
          element={<Accreditation.Interests />}
        />
      </Route>
      <Route path={integrations.base}>
        <Route
          path={integrations.children.facebookAuth}
          element={<FacebookAuth />}
        />
        <Route path={'mk-sso'} element={<MKAuth />} />
      </Route>
      {/* <Route path={'/faq'} element={<HelpCenter isPublic />} /> */}
      {/* FIX: Rotas countdown, entender melhor o pq do erro em Rota privada com Outler*/}
      <Route path={countdown.base}>
        <Route
          path={countdown.children.base}
          element={<Accreditation.Countdown />}
        />
      </Route>
      {/*
        <PrivateRoute path={countdown.base} element={<Outlet />}>
          <PrivateRoute
            path={countdown.children.userCountdown}
            element={<Accreditation.UserCountdown />}
          >
        </PrivateRoute>
      */}
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Helment />
  </BrowserRouter>
);

export default AppRoutes;
