//import { ICalendar } from 'datebook';
import {
  ScheduleList as ScheduleListType,
  Topic as Topics,
} from 'useCases/schedules';
//import { useTranslate } from 'hooks';
import { Topic } from 'components/contexts/schedule';
import { SubTitle } from 'components/structure';
import { Skeleton } from './ScheduleList.skeleton';
import * as S from './ScheduleList.styles';

export type ScheduleListProps = {
  isLoading: boolean;
  streamGroups: ScheduleListType[];
};

export const ScheduleList = ({
  isLoading,
  streamGroups,
}: ScheduleListProps) => {
  // const translate = useTranslate();
  if (isLoading) {
    return <Skeleton />;
  }

  // const config = (ics: Array<Ics>) => ({
  //   title: ics[0].title,
  //   location: ics[0].location,
  //   description: ics[0].description,
  //   start: new Date(ics[0].startTime),
  //   end: new Date(ics[0].endTime),
  // });

  // const icalendar = (ics: Array<Ics>) => new ICalendar(config(ics)).download();

  const OrderTopcs = (topics: Array<Topics>) => {
    topics.sort((prev, curr) => {
      if (
        new Date(prev?.details?.timeSlot?.start) <
        new Date(curr?.details?.timeSlot?.start)
      ) {
        return -1;
      }
      if (
        new Date(prev?.details?.timeSlot?.start) >
        new Date(curr?.details?.timeSlot?.start)
      ) {
        return 1;
      }
      return 0;
    });

    return topics;
  };

  return (
    <S.ScheduleSection>
      <S.Wrapper>
        {streamGroups?.map(({ topics, id, name }) => (
          <S.Container key={id}>
            <S.ScheduleTopContent>
              <SubTitle
                style={{ fontSize: '24px', fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{ __html: name || '' }}
                as="h4"
              ></SubTitle>
              {/* {ics?.[0] && (
                <S.ButtonOnAgenda onClick={() => icalendar(ics)}>
                  <text>&#xa0;&#xa0;{translate('schedule.saveOnAgenda')}</text>
                </S.ButtonOnAgenda>
              )} */}
            </S.ScheduleTopContent>
            {OrderTopcs(topics).map((event, i) => (
              <>
                <Topic
                  key={event.description}
                  {...event}
                  hasDetails={Boolean(event.details.speakers.length)}
                  beforeTimeSlot={
                    i - 1 < 0
                      ? { start: '', end: '' }
                      : topics[i - 1].details.timeSlot
                  }
                />
              </>
            ))}
          </S.Container>
        ))}
      </S.Wrapper>
    </S.ScheduleSection>
  );
};
