/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { useUserById } from './users';

type LikesReturned = {
  id?: string;
  userId: string;
  eventId: string;
  streamId: string;
  messageId: string;
  reactType?: string;
};

export const useLikes: any = (streamId: string, likesFromMessages: any) => {
  const socket = useRef<SocketIOClient.Socket>();
  const { data } = useUserById(localStorage.getItem('id') || '');
  const [likes, setLikes] = useState<any>(likesFromMessages);
  const [loadedFromAPI, setLoadedFromAPI] = useState(false);

  useEffect(() => {
    if(!loadedFromAPI && likesFromMessages?.length > 0){
      setLikes(likesFromMessages);
      setLoadedFromAPI(true)
    }
  }, [likesFromMessages]);


  useEffect(() => {
    socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL!, {
      path: '/likes',
      transports: ['websocket'],
      auth: {
        accessToken: data?.accessToken,
      },
    });

    socket.current.on('connected', () => {
      socket.current?.emit('join_stream', {
        streamId: streamId,
      });

      socket.current?.on(
        'share_like',
        ({
          id,
          userId,
          reactType,
          eventId,
          streamId,
          messageId,
        }: LikesReturned) => {
          setLikes((prevState: any) => {
            prevState = prevState ? prevState : [];
            if (reactType == 'dislike') {
              return [...prevState].filter(
                (like) =>
                  !(like.userId == userId && like.messageId == messageId),
              );
            }
            return [
              ...prevState,
              { id, userId, eventId, streamId, messageId },
            ].filter((l) => l);
          });
        },
      );

      socket.current?.on(
        'share_to_all',
        ({
          id,
          userId,
          eventId,
          streamId,
          messageId,
          reactType,
        }: LikesReturned) => {
          setLikes((prevState: any) => {
            prevState = prevState ? prevState : [];
            if (reactType == 'dislike') {
              return [...prevState].filter(
                (like) =>
                  !(like.userId == userId && like.messageId == messageId),
              );
            }
            return [
              ...prevState,
              { id, userId, eventId, streamId, messageId },
            ].filter((l) => l);
          });
        },
      );
      socket.current?.on('dislike', ({ id, userId }: LikesReturned) => {
        setLikes((prevState: any) => {
          const newArray = [...prevState].filter(
            (element) => !(element.userId == userId && element.id == id),
          );

          [...newArray];
        });
      });
    });
    return () => {
      socket.current?.emit('leave_stream', {
        streamId: streamId,
      });

      socket.current?.close();
    };
  }, [data?.accessToken, streamId]);

  const handleSendReaction = useCallback(
    (
      event: 'new_like' | 'dislike',
      { id, reactType, userId, eventId, streamId, messageId }: LikesReturned,
    ) => {
      if (userId) {
        socket.current?.emit(event, {
          userId: userId,
          eventId: eventId,
          messageId: messageId,
          streamId: streamId,
          reactType,
          id,
        });
      }
    },
    [],
  );

  return {
    handleSendReaction,
    likes,
    setLikes,
  };
};
